import { Platform, Text } from "react-native";

import React from "react";
import MaskedView from "@react-native-masked-view/masked-view";

let LinearGradient;
if (Platform.OS !== "web") {
  LinearGradient = require("react-native-linear-gradient").default;
}
export const GradientText = (props) => (
  <>
    {Platform.OS === "web" && (
      <Text
        {...props}
        style={[
          props.style,
          {
            background:
              "linear-gradient(117.63deg, #0050FF 0.32%, #9000FF 81.21%, #B61CD4 96.62%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          },
        ]}
      />
    )}
    {Platform.OS !== "web" && (
      <MaskedView maskElement={<Text {...props} />}>
        <LinearGradient
          colors={["#0050FF", "#9000FF", "#B61CD4"]}
          start={{ x: 0.1, y: 0 }}
          end={{ x: 0.9, y: 1 }}
        >
          <Text {...props} style={[props.style, { opacity: 0 }]} />
        </LinearGradient>
      </MaskedView>
    )}
  </>
);
