import React, { useEffect, useState } from "react";
import { Platform, Pressable, StyleSheet, View, Image } from "react-native";
import * as DocumentPicker from "expo-document-picker";

import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
// import * as Sentry from "sentry-expo"; // Constants
import { colors } from "../helpers/colors";
import { RootState } from "../../types";

// Components
import MayaText from "../components/typography/MayaText";
import ButtonWide from "../components/general/ButtonWide";

// Icons
import PickFromIcon from "../icons/pickFrom";
import {
  trainingFilesAsyncThunk,
  trainingMultiPdfAsyncThunk,
} from "../api/training/training";
import { AppDispatch } from "../store/store";
import FilesSelected from "../components/training/ChatFooter";
import { clearState, setLoading } from "../reducers/trainingSlice";
import MobileViewHOC from "./MobileViewHOC";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../navigation/routeNames";
import { sentryErrorService } from "../services/sentry-error.service";
import { setNotification } from "../reducers/notificationSlice";
import { ETrainingStatus, TrainingEvent } from "../models/trainingEvent.model";
import { isWireScreen } from "../helpers/utils";

const DocumentTrainingHOC = ({ uploadType, navigation, onStartTraining }) => {
  // const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { userId } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const trainingReducer = useSelector(
    (state: RootState) => state.root.trainingReducer
  );
  const { baseUrl } = useSelector(
    (state: RootState) => state.root.generalReducer
  );
  const route = useRoute();

  const type = uploadType || "Document";
  const [files, setFiles] = useState<DocumentPicker.DocumentPickerAsset[]>([]);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const pickDocument = async () => {
    try {
      // dispatch(setLoading(true));
      let result = await DocumentPicker.getDocumentAsync({
        type:
          type === "Document"
            ? [
                "application/docx",
                "text/plain",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/pdf",
              ]
            : [
                "text/csv",
                "text/x-csv",
                "application/vnd.ms-excel.sheet.macroEnabled.12",
                "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
              ],
        multiple: true,
      });
      // dispatch(setLoading(false));
      if (!result) {
        return;
      }
      if (result && result.assets && result.assets.length > 0) {
        uploadMultipleDocument(result.assets);
      }
    } catch (error) {
      dispatch(setLoading(false));
      // sentryErrorService(error);
      console.error("pickDocument", error);
    }
  };
  const uploadMultipleDocument = (
    files: DocumentPicker.DocumentPickerAsset[]
  ) => {
    try {
      if (files.length === 0) return;
      setFiles(files);
    } catch (error) {
      // sentryErrorService(error);
      console.log("Error uploading file: ", error);
    }
  };

  const uploadDocument = async () => {
    try {
      const formData = new FormData();
      files.forEach((file: any) => {
        if (Platform.OS === "web") {
          console.log({
            uri: file.uri,
            name: file.name,
            type: file.mimeType,
          });

          // formData.append("file", file);
          formData.append("file", file!.file!);
        } else {
          formData.append("file", {
            uri: file.uri,
            name: file.name,
            type: file.mimeType,
          });
        }
      });

      dispatch(trainingFilesAsyncThunk({ formData: formData }));

      const event = {
        message: "Training begins.",
        status: ETrainingStatus.PROCESSING,

        filename: "",
        ws_category: "TRAINING_STATUS",
        read: true,
      } as TrainingEvent;
      dispatch(setNotification(event));

      setFiles([]);

      if (isWireScreen()) {
        onStartTraining && onStartTraining();
      } else {
        navigation.navigate(RouteNames.Chat);
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
      // sentryErrorService(error);
      setResponseMessage("Error uploading file");
    }
  };
  const handlerCLearFilterFromChat = (fileName: string) => {
    setFiles((prev) => {
      return prev.filter((item, i) => item.name !== fileName);
    });
  };

  useEffect(() => {
    dispatch(clearState());
    setResponseMessage("");
  }, []);

  // useEffect(() => {
  //   if (trainingReducer.result.message) {
  //     setResponseMessage(trainingReducer.result.message);
  //     navigation.navigate(RouteNames.Chat);
  //     return;
  //   }

  //   return () => {
  //     setResponseMessage("");
  //   };
  // }, [trainingReducer, trainingReducer.result]);

  return (
    <View style={styles.container}>
      <MayaText
        style={{
          fontSize: 14,
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        Team Maya, only give me really, really good stuff... Seriously.
      </MayaText>
      <Pressable style={styles.cameraContainer} onPress={pickDocument}>
        {files.length > 0 && (
          <FilesSelected
            files={files}
            onClearFile={handlerCLearFilterFromChat}
          />
        )}

        <View
          style={[styles.uploadZone, files.length > 0 && styles.uploadZoneMini]}
        >
          {files.length == 0 && (
            <Image
              style={{
                width: 40,
                height: 40,
                marginBottom: 14,
              }}
              source={require("../assets/newDesign/document-upload-2.png")}
            />
          )}
          <MayaText
            style={{
              fontSize: 14,
              color: "#0050FF",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Upload file here
          </MayaText>
          {/* <MayaText
              style={{ fontSize: 12, color: "#FFFFFFBF", marginTop: 5 }}
            >
              {type === "DOCUMENT" ? "DOCX, TXT, PDF up to 10MB" : " "}
            </MayaText> */}
        </View>
      </Pressable>

      <View>
        <MayaText
          style={{ fontSize: 14, fontWeight: "400", textAlign: "center" }}
        >
          {type === "Document"
            ? "File should be Docx, txt, PDF up to 10MB"
            : "File should be CSV, XLS, XLSX up to 10MB"}
        </MayaText>
        {/* <MayaText
          style={{
            fontSize: 18,
            fontWeight: "700",
            textAlign: "center",
            marginTop: 30,
          }}
        >
          {responseMessage}
        </MayaText> */}

        <ButtonWide
          text={"Start training"}
          onPress={files && uploadDocument}
          disabled={files.length === 0 || trainingReducer?.loading}
          loading={trainingReducer?.loading}
          style={{ padding: 10, marginTop: 24 }}
        />
      </View>
    </View>
  );
};

export default DocumentTrainingHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 20,
    height: "100%",
  },
  filesSelected: {
    padding: 5,
    textAlign: "left",
  },
  cameraContainer: {
    // height: "auto",
    // minHeight: 300,
    // backgroundColor: colors.background2,
    // marginBottom: 20,
    // borderRadius: 30,
    // justifyContent: "center",

    marginTop: 24,
    marginBottom: 24,
  },
  buttonWrapper: {
    // borderTopColor: colors.borderColor,
    // borderTopWidth: 1,
    // alignSelf: "center",
    // alignItems: "center",
    // paddingVertical: "10%",
    // width: 300,
  },
  uploadZone: {
    borderWidth: 1,
    borderColor: "#4F86FF",
    borderStyle: "dotted",
    borderRadius: 8,
    height: 158,
    backgroundColor: "#EDF3FF",
    paddingVertical: 16,

    justifyContent: "center",
    alignItems: "center",
  },
  uploadZoneMini: {
    height: 56,
  },
});
