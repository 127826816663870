import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const CloseIcon = (props) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M8.00065 15.6666C4.04732 15.6666 0.833984 12.4533 0.833984 8.49992C0.833984 4.54659 4.04732 1.33325 8.00065 1.33325C11.954 1.33325 15.1673 4.54659 15.1673 8.49992C15.1673 12.4533 11.954 15.6666 8.00065 15.6666Z"
      fill={props.fill}
    />
    <Path
      d="M6.11357 10.8867C5.9869 10.8867 5.86023 10.84 5.76023 10.74C5.5669 10.5467 5.5669 10.2267 5.76023 10.0333L9.53357 6.25999C9.7269 6.06666 10.0469 6.06666 10.2402 6.25999C10.4336 6.45332 10.4336 6.77332 10.2402 6.96666L6.4669 10.74C6.37357 10.84 6.24023 10.8867 6.11357 10.8867Z"
      fill="white"
    />
    <Path
      d="M9.8869 10.8867C9.76023 10.8867 9.63357 10.84 9.53357 10.74L5.76023 6.96666C5.5669 6.77332 5.5669 6.45332 5.76023 6.25999C5.95357 6.06666 6.27357 6.06666 6.4669 6.25999L10.2402 10.0333C10.4336 10.2267 10.4336 10.5467 10.2402 10.74C10.1402 10.84 10.0136 10.8867 9.8869 10.8867Z"
      fill="white"
    />
  </Svg>
);
