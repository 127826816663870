import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Linking,
  Platform,
  StyleSheet,
  TextInput,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { asyncStorage } from "reactotron-react-native";

// Components
//import LoginLayout from "../components/login/LoginLayout";
import FooterText from "../components/typography/FooterText";
import MayaTitle from "../components/typography/MayaTitle";
import ButtonWide from "../components/general/ButtonWide";
import MayaText from "../components/typography/MayaText";
import CustomTextInput from "../components/general/CustomTextInput";
// Constants
import { RouteNames } from "../navigation/routeNames";
import { colors } from "../helpers/colors";
import {
  setError,
  setLoading,
  setToken,
  setUserPressLogout,
} from "../reducers/authSlice";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import { loginAsyncThunk, submitTokenAsyncThunk } from "../api/auth/auth";
import { AppDispatch } from "../store/store";
import Layout from "../components/Layout/Layout";
import LoadingHOC from "./LoadingHOC";
import { useKeyboard } from "../hooks/useKeyboard";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { STORAGE_KEYS } from "../helpers/constants";
import { reset } from "../reducers/chatSlice";
import { isWireScreen } from "../helpers/utils";

type Props = {};

const LoginHOC: React.FC<Props> = ({}: Props) => {
  const isKeyBoardActive = useKeyboard();
  const { loading, error, token, userId, isUserPressLogout } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const [authToken, setAuthToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSelected, setSelection] = useState(null);

  const handleLogin = () => {
    if (isUserPressLogout) {
      dispatch(loginAsyncThunk({ email, password }));
      return;
    }
    dispatch(setToken(true));

    dispatch(submitTokenAsyncThunk({ registrationToken: authToken }));
  };
  const navigateToRegister = () => {
    navigation.navigate(RouteNames.TopRegisterBar);
  };
  // useEffect(() => {
  //   dispatch(setLoading(false));
  //   dispatch(setError(undefined));
  // }, []);

  useEffect(() => {
    getUserEP();
    if (token) {
      navigation.navigate(RouteNames.Chat);
    }
    // if (Platform.OS == 'android' && isSelected) {
    //   navigation.navigate(RouteNames.Chat);
    //   console.log("ER TOKEN DE EMSIP", isSelected)

    // }
  }, [token]);
  useEffect(() => {
    if (isUserPressLogout) {
      dispatch(reset());
    }

    if (token) {
      dispatch(reset());
      navigation.navigate(RouteNames.Chat);
    }
  }, [isUserPressLogout, token, isSelected]);

  const getUserEP = async () => {
    setSelection(await AsyncStorage.getItem(STORAGE_KEYS.TOKEN));
  };

  // if (loading) {
  //   return <LoadingHOC />;
  // }

  if (loading) {
    return <LoadingHOC />;
  }
  return (
    <View
      style={[
        {
          backgroundColor: "white",
          height: "100%",
          // justifyContent: "center",
          // flexDirection: "row",
          alignItems: "center",
          // justifyContent: "center",

          // borderWidth: 1,
        },
      ]}
    >
      <KeyboardAvoidingView
        style={[
          { height: 500, width: "100%" },
          isWireScreen() && {
            width: 800,
            // borderWidth: 1,
          },
        ]}
        keyboardVerticalOffset={Platform.OS === "android" ? 60 : 40}
        behavior={"position"}
      >
        <View
          style={{
            paddingTop: 84,
            paddingHorizontal: 32,
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <View
            style={{
              // zIndex: 1,
              // bottom: "195%",
              // alignSelf: "flex-start",
              // left: 40,
              // flexDirection: "row",
              marginBottom: 40,
            }}
          >
            <Image
              source={require("../assets/newDesign/frog.png")}
              style={{ width: 40, height: 40 }}
            />
            {/* <MayaText style={{alignContent:'flex-start' ,fontWeight:'bold',left:30,top:'3%'}}>
             Do not have an account
          </MayaText>
          <MayaText style={{color:'black',alignContent:'flex-start' ,fontWeight:'bold',left:38,top:'3%'}}>
             Sign up
          </MayaText> */}
          </View>

          <View
            style={{
              // bottom: Platform.OS == "android" ? 500 : 600,
              // alignSelf: "flex-start",
              // left: 40,
              marginBottom: 32,
            }}
          >
            <MayaTitle
              style={[
                styles.welcomeText,
                {
                  textAlign: "left",
                },
              ]}
            >
              Welcome Back!
            </MayaTitle>

            <MayaText
              style={{
                color: "#161C2D",
                fontWeight: "600",
                fontSize: 14,
              }}
            >
              {isUserPressLogout
                ? "Sign in with email address"
                : "Sign in with token"}
            </MayaText>
          </View>

          <View style={[styles.inputContainer]}>
            {isUserPressLogout ? (
              <View>
                <CustomTextInput
                  value={email}
                  setValue={setEmail}
                  placeholder="Email"
                  isPassword={false}
                  secureTextEntry={false}
                  style={{
                    height: 48,
                    // padding: 10,
                    // width: 330,
                  }}
                />
                <CustomTextInput
                  value={password}
                  setValue={setPassword}
                  placeholder="Password"
                  secureTextEntry={true}
                  isPassword={true}
                  style={{
                    height: 48,
                    // padding: 10,
                    // width: 330,
                  }}
                />
              </View>
            ) : (
              <CustomTextInput
                value={authToken}
                setValue={setAuthToken}
                placeholder="Token"
                secureTextEntry={true}
                isPassword={true}
                style={{
                  height: 48,
                  // padding: 10,
                  // width: 330,
                }}
              />
            )}

            {error && (
              <View style={styles.errorMessageContainer}>
                <MayaText style={styles.errorMessageText}>{error}</MayaText>
              </View>
            )}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                bottom: 25,
                alignSelf: "flex-start",
              }}
            >
              {/* 2BDONE */}
              {/* <View style={{right:40,bottom:18,}}>
<CheckBox
           checked={null}
           onPress={null}
           iconType="material-community"
           checkedIcon="checkbox-outline"
           uncheckedIcon={'checkbox-blank-outline'}
         />
</View> */}
              {/* <MayaText style={{fontSize:14,right:45,color:'black'}}>
           Remember account
          </MayaText>
          <MayaText style={{fontSize:14,left:5,color:'blue'}}>
           Forgot password
          </MayaText> */}
            </View>
            <ButtonWide
              disabled={
                isUserPressLogout ? !email && !password : authToken == ""
              }
              loading={loading}
              text="Continue"
              onPress={handleLogin}
            />
          </View>
        </View>

        {/* {isKeyBoardActive === 0 ? (
        <View style={styles.footerContainer}>
          <FooterText
            textWhite="I am new,"
            textBlue="Create a token :)"
            onPress={navigateToRegister}
          />
        </View>
      ) : null} */}
        <View
          style={{ flexDirection: "row", alignSelf: "center", bottom: "20%" }}
        >
          {/* <MayaText  style={styles.terms}>
     This site is protected by the  
   </MayaText> */}

          {/* <MayaText style={styles.termsLink}>
      Privacy Policy.
   </MayaText> */}
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default LoginHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "115%",
    resizeMode: "stretch",
  },
  welcomeText: {
    // position: "absolute",
    // fontWeight: "bold",
    // bottom: 30,
    // fontSize: 28,
    // zIndex: 1,
    color: "#161C2D",
    fontSize: 32,
    fontWeight: "700",
    marginBottom: 32,
  },
  inputContainer: {
    // display: "flex",
    // position: "absolute",
    // bottom: 100,
    // width: "80%",
    // zIndex: 2,
    // borderRadius: 10,
    // padding: 20,
  },
  textInput: {
    height: 50,
    marginBottom: 10,
  },
  button: {
    height: 46,
    alignSelf: "center",
    marginBottom: 10,
  },
  terms: {
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 5,
    textAlignVertical: "center",
    justifyContent: "center",
  },
  termsLink: {
    justifyContent: "center",

    fontSize: 14,
    color: "black",
    fontWeight: "bold",
    marginLeft: 5,
    textAlignVertical: "center",
    paddingHorizontal: 10,
  },
  errorMessageContainer: {
    marginBottom: 10,
    width: "100%",
    height: 16,
    flexDirection: "row",
    justifyContent: "center",
  },
  errorMessageText: {
    color: "red",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  footerContainer: {
    position: "absolute",
    bottom: 20,
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
});
