import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  Image,
  Keyboard,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Audio } from "expo-av";
import { GiftedChat, IMessage, MessageProps } from "react-native-gifted-chat";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types";
import CustomChatInput from "../components/customChatInput/CustomChatInput";
import { colors } from "../helpers/colors";
import { ChatMessage } from "../models/message";
import { ETrainingStatus, TrainingEvent } from "../models/trainingEvent.model";
import { RouteNames } from "../navigation/routeNames";
import { setNotification } from "../reducers/notificationSlice";
import { socket as webSocket } from "../services/socket";
import { AppDispatch } from "../store/store";
import { Image as RNImage } from "react-native";
import AvatarChat from "../components/AvatarChat/AvatorChat";
import BubbleRender from "../components/Bubble/BubbleRender";
import useNotification from "../hooks/useNotification";
import {
  setProcessingFilesEnd,
  setTrainingProcessing,
} from "../reducers/trainingSlice";
//import { sentryErrorService } from "../services/sentry-error.service";
import { workFlowSendAsyncThunk } from "../api/doing-work/doing-work";
import useHayMayaRecognition from "../hooks/useHayMayaRecognition";
import { useKeyboard } from "../hooks/useKeyboard";
import { Eedges_consumable, IDoingWork } from "../models/doingWotrk.model";
import {
  addLoadingMessageBubble,
  removeLoading,
  setAnswerToQuestion,
  setFlowByUser,
  setMessagesChat,
  setWorkOption,
  stopWorkFlow,
  toggleHeart,
} from "../reducers/chatSlice";
import { getTheDoingWorkWithOption } from "../services/doing-work";

import dayjs from "dayjs";
import RenderHTML from "react-native-render-html";
import AutoTemplateActionSheet from "../components/AutoTemplateActionSheet/AutoTemplateActionSheet";
import { GradientText } from "../components/typography/GradientText";
import { EChatHeightOnOpenActionBanner } from "../helpers/chat";
import { isMessageContainsUrl } from "../helpers/utils";
//import useAndroidRecog from "../hooks/useAndroidRecognition";
import { setTranscript, stopRecognition } from "../reducers/recognitionSlice";
import {
  appendMessage,
  formatMessageForChat,
  getSelectedNodeBasedOnStep,
  stopProcessWorkFlow,
} from "../services/chat.service";
import { PubSubEvents, pubSubChat } from "../services/pub-sub.service";
import { Loading } from "../components/Animations/Loading";
import Markdown from "react-native-markdown-display";
import WebView from "react-native-webview";
import { handleQuit } from "../components/general/MenuContent";
import { readDocsByUserIdAsyncThunk } from "../api/doc/doc";
import { UUID } from "../helpers/constants";
import { WrapperView } from "../components/Layout/WrapperView";
import { isWireScreen } from "../helpers/utils";

let globalTimeout = null;
type ChatHOCProps = {
  navigation: NativeStackNavigationProp<any>;
  route: any;
};
const ChatHOC: React.FC<ChatHOCProps> = ({ route }) => {
  const { wakeUp } = route?.params ?? false;
  const [voiceMessage, setVoiceMessage] = useState<ChatMessage>(null);

  const trainingEvent = useNotification();
  const {
    message,
    volumeChange,
    voiceAmplitude,
    recognitionEnd,
    onResetVoiceMessage,
    speechPause,
    startRecognitionMobile,
    stopRecognitionMobile,
    startRecognitionWeb,
    stopRecognizingWeb,
    recognition,
    swapModal,
    recognitionError,
    setRecognitionError,
  } = useHayMayaRecognition();

  // const {
  //   startRecording,
  //   androidTranscript,
  //   stopRecording,
  //   androidMessage,
  //   isRecording,
  //   recording,
  // } = useAndroidRecog();
  useEffect(() => {
    if (recognition) {
      console.log(" recognitio", recognition);
    }
  }, [recognition]);

  const keyboardHeight = useKeyboard();

  const { processingFiles } = useSelector(
    (state: RootState) => state.root.trainingReducer
  );
  const [speechModal, setSpeechModal] = useState(true); //
  const { selectedByUserFlow, activateWorkFlow, workFlow } = useSelector(
    (state: RootState) => state.persist.chatReducer
  );
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const chatState = useSelector(
    (state: RootState) => state.persist.chatReducer
  );

  const { baseChatUrl } = useSelector(
    (state: RootState) => state.root.generalReducer
  );
  const dimentionState = useSelector(
    (state: RootState) => state.root.dimentionReducer
  );
  const { token, profile, userId } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const [messages, setMessages] = useState([]);
  const [isPauseSpeech, setIsPauseSpeech] = useState(false);
  const textInputRef = useRef(null);
  const [reconnectionCounter, setReconnectionCounter] = useState(0);
  const [socket, setSocket] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [joinTheRoom, setJoinTheRoom] = useState(false);
  const [minInputToolbarHeight, setMinInputToolbarHeight] = useState(100);
  const [HandleVoiceIsRecognizing, setHandleVoiceIsRecognizing] =
    useState(true);
  const [isPlaying, setIsPlaying] = useState(false); //
  const [textToCount, setTextToCount] = useState(0);
  const [isFocused, setIsFocused] = useState(false); //
  const [permissionResponse, requestPermission] = Audio.usePermissions();
  const [soundObject, setSoundObject] = useState(new Audio.Sound() || null);

  const imageSource = require("../assets/newDesign/pdf-3375.png");
  console.log(imageSource.default);

  const imageSourceResolved =
    Platform.OS == "web"
      ? { uri: "https://cdn-icons-png.flaticon.com/512/201/201153.png" } // tmp img
      : RNImage.resolveAssetSource(imageSource);

  const goChat = (text: string) => {
    setIsFocused(true);
  };
  const pickDocument = async () => {
    navigation.navigate(RouteNames.TrainingList);
  };
  // useEffect(() => {
  //   if (speechModal && !isPlaying && Platform.OS == "ios") {
  //     setTimeout(() => {
  //       handleStartRecord();
  //     }, 3000);
  //   }
  // }, [isPlaying]);

  useEffect(() => {
    if (voiceMessage && voiceMessage.audio) {
      playVoice(voiceMessage);
    }
  }, [voiceMessage]);
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      // Reset all your states here
      setTextToCount(0);
      // setIsFocused(false);
      setSpeechModal(false);
    });

    return unsubscribe;
  }, []);
  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", () => {
      console.log("Back button used for navigation");
      setIsFocused(false);
    });
  }, []);

  useEffect(() => {
    // alert(`recognitionEnd ${recognitionEnd}`);

    if (recognitionEnd) {
      console.log("Recognition  :", recognition);
      setHandleVoiceIsRecognizing(true);
      if (swapModal) {
      }
    }
  }, [recognitionEnd]);

  const removeLoadingAppendMessage = (
    message: ChatMessage,
    userResponse?: string
  ) => {
    dispatch(addLoadingMessageBubble());
    dispatch(setMessagesChat(message));
  };

  const mayaLoading = () => {
    dispatch(addLoadingMessageBubble());
  };

  // if (voiceAmplitude) alert(voiceAmplitude);

  const handlerUserSendWorkFlow = async (
    text: string,
    correctUrl?: boolean,
    prevMessageRequestId?: string
  ) => {
    const selectedByTheUser = chatState.selectedByUserFlow;
    console.log(selectedByTheUser);

    if (!selectedByTheUser) {
      return;
    }

    const selectOption = chatState.workOptions.edges_consumable.find(
      (edge) => edge.from_node === selectedByTheUser.to_node
    );
    console.log("Dag selected option ", selectOption);

    const answerSelected = getSelectedNodeBasedOnStep(
      chatState.workOptions,
      selectedByTheUser.to_node
    );
    //OJO
    // console.log("Dag selected response ", chatState);
    if (!answerSelected) {
      stopProcessWorkFlow(dispatch);
      return;
    }

    if (answerSelected.id === "ask_url" && !correctUrl) {
      setMessageForIncorrectUrl();
      return;
    }

    dispatch(
      setAnswerToQuestion({
        answer: text,
        selectedNodeToAnswer: answerSelected,
      })
    );

    const nextQuestion = chatState.workOptions.nodes_consumable.find(
      (node) => node.id === selectOption.to_node
    );

    // * STOP PROCESS
    if (!nextQuestion) {
      stopProcessWorkFlow(dispatch);
      return;
    }

    // Dynamically update the last user answer in the response_message
    const updatedNodesConsumable = [...chatState.workOptions.nodes_consumable];
    const updatedEdgesConsumable = [...chatState.workOptions.edges_consumable];

    const updatedNodeIndex = updatedNodesConsumable.findIndex(
      (noded) => noded.id === answerSelected.id
    );

    updatedNodesConsumable[updatedNodeIndex] = {
      ...updatedNodesConsumable[updatedNodeIndex],
      answers: text,
    };

    dispatch(
      setWorkOption({
        ...chatState.workOptions,
        nodes_consumable: updatedNodesConsumable,
      })
    );

    let updatedAnswer = {
      edges_consumable: updatedEdgesConsumable,
      nodes_consumable: updatedNodesConsumable,
      id: chatState.workFlow.set,
    };
    console.log("updatedAnswer this can be commented later", updatedAnswer);

    const nextEdge = chatState.workOptions.edges_consumable.find(
      (edge) => nextQuestion.id === edge.to_node
    );

    dispatch(setFlowByUser(nextEdge));

    const formatMessage = formatMessageForChat({
      message: nextQuestion.response_message,
    });

    if (prevMessageRequestId) formatMessage.request_id = prevMessageRequestId;

    if (nextQuestion.id === Eedges_consumable.END) {
      formatMessage.request_id += "--last-workflow";

      dispatch(stopWorkFlow());

      dispatch(workFlowSendAsyncThunk({ dagFile: updatedAnswer }));
    }

    removeLoadingAppendMessage(formatMessage);
  };

  const startRecording = () => {
    if (Platform.OS != "web") {
      startRecognitionMobile().catch(console.error);
    } else {
      startRecognitionWeb();
    }
  };

  const setMessageForWorkFlow = (option: IDoingWork, text: string) => {
    const messages = formatMessageForChat({
      message: text,
      userId,
      profileName: profile?.name,
    });
    dispatch(setMessagesChat(messages));
    mayaLoading();

    const startOption = option.edges_consumable.find(
      (edges) => edges.from_node === Eedges_consumable.START
    );

    const firstOption = option.nodes_consumable.find(
      (node) => node.id === startOption.to_node
    );

    dispatch(setFlowByUser(startOption));
    const messageFromMaya = formatMessageForChat({
      message: firstOption.response_message,
    });
    messageFromMaya.request_id = messages.request_id;
    console.log("DAGS HERE", messageFromMaya);

    removeLoadingAppendMessage(messageFromMaya);
  };
  //$$$$$
  const setMessageForNotWorkFlow = () => {
    const messageSendFromMaya = formatMessageForChat({
      message: "At present, Maya does not possess this workflow.",
    });
    removeLoadingAppendMessage(messageSendFromMaya);
  };
  const setMessageForIncorrectUrl = () => {
    const messageSendFromMaya = formatMessageForChat({
      message: "The URL provided have an incorrect format, please try again.",
    });
    removeLoadingAppendMessage(messageSendFromMaya);
  };

  const handleNewMessage = useCallback(
    (message: any) => {
      const isJoinRoom = message ? message.includes("joined") : false;
      setJoinTheRoom(isJoinRoom);
      console.log("this is the new message", message);
    },
    [speechModal]
  );

  const handlerErrorConnection = useCallback(
    (e: any) => {
      console.error("Connection error:", e);
      //sentryErrorService(e);
      setMessages((prevMessages) =>
        appendMessage(prevMessages, [
          formatMessageForChat({
            message: "Unable to connect to the server. Trying to reconnect.",
          }),
        ])
      );
      setReconnectionCounter(reconnectionCounter + 1);
    },
    [reconnectionCounter, speechModal]
  );

  const handleReconnect = (e: any) => {
    console.error(
      "Reconnection failed after several attempts. Server might be down."
    );
  };

  // Function to play audio
  const playVoice = async (message: ChatMessage) => {
    let played = false;
    try {
      // setIsPlaying(true);
      console.log("Loading Sound");

      // Load the sound

      await soundObject.loadAsync({ uri: message.audio });

      // Set up playback status update listener
      const onPlaybackStatusUpdate = (status) => {
        if (!status.isLoaded) {
          // Audio not loaded
          console.log("Audio not loaded");

          return;
        }

        if (status.isPlaying) {
          // Audio is playing
          console.log("Audio is playing");
          // You may update state or perform other actions here

          if (!played) {
            console.log("THE MODAL TRIGGERED :", speechModal);
            removeLoadingAppendMessage(message);
            played = true;
          }
        }

        if (status.didJustFinish) {
          // Audio playback finished
          console.log("Audio playback finished");
          // Perform cleanup and any additional actions here
          soundObject.unloadAsync(); // Unload the sound
          setVoiceMessage(null);
          setIsPlaying(false); // Update state

          if (speechModal) startRecording();
        }
      };

      // Set the playback status update listener
      soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);

      if (!speechModal) throw new Error("stop voice");

      // Start playback
      await soundObject.playAsync();
    } catch (error) {
      if (!played) {
        console.log("THE MODAL TRIGGERED :", speechModal);
        removeLoadingAppendMessage(message);
        played = true;
      }

      console.log("Error playing audio:", error);
      setIsPlaying(false);
    }
  };

  const handleStop = async (soundObject) => {
    try {
      if (soundObject) {
        // Unload the sound
        await soundObject.stopAsync();
        await soundObject.unloadAsync();
        console.log("Audio playback stopped");
        // Reset state
        setVoiceMessage(null);
        setIsPlaying(false);

        startRecording();
      }
    } catch (error) {
      console.error("Error stopping audio:", error);
    }
  };
  // submit to voice maker:
  const submitFormAndClick = async (
    answer: ChatMessage
  ): Promise<string | null> => {
    try {
      const payload = {
        Engine: "neural",
        VoiceId: "ai4-en-US-Ariana",
        LanguageCode: "en-US",
        Text: answer.text,
        OutPutFormat: "mp3",
        SampleRate: 48000,
        Effect: "default",
        MasterSpeed: "0",
      };

      const response = await fetch(
        "https://developer.voicemaker.in/voice/api",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer 6ec49bf0-3390-11ee-af45-27dd0c44c77c",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.path);
        answer.audio = responseData.path;
        setVoiceMessage(answer);

        console.log("THE TRANSCRIPT NEW NOW :", message);
        // return responseData?.data?.url ?? null;
      } else {
        console.log("Bad response:", response.statusText);

        // return null;
      }
    } catch (error) {
      console.error("Error posting data to Platform. Network error.", error);
      return null;
    }
  };

  const countWords = (inputText) => {
    if (inputText.trim() === "") return 0;
    return parseInt(inputText.trim().split(/\s+/).length);
  };

  const handleNewAnswer = useCallback(
    (answer: any) => {
      setLoading(false);
      if (globalTimeout) clearTimeout(globalTimeout);

      console.log("THIS IS FOR VOICE :", answer);
      const timeStamp = dayjs.unix(answer?.timestamp).toDate();
      const formatAnswer = formatMessageForChat({
        message: answer.message,
        timestamp: timeStamp,
        request_id: answer.request_id,
      });

      if (speechModal) {
        submitFormAndClick(formatAnswer);
      } else {
        console.log("THE MODAL TRIGGERED :", speechModal);
        removeLoadingAppendMessage(formatAnswer);

        // }
        const wordCount = countWords(answer.message);
        const timer = wordCount > 15 ? wordCount / 2.5 : wordCount / 2;
        console.log("message words count ", timer * 1000);

        setTextToCount(timer * 1000);
      }
    },
    [speechModal]
  );

  const handleEventTraining = (event: TrainingEvent) => {
    if (event.status === ETrainingStatus.PROCESSING) {
      console.log("PROCESSING", event);
      dispatch(setTrainingProcessing(true));
      // dispatch(setNotification(event));

      return;
    }
    if (event.status === ETrainingStatus.SUCCESS) {
      console.log("SUCCESS", event);

      dispatch(readDocsByUserIdAsyncThunk({}));

      dispatch(setProcessingFilesEnd());
      dispatch(setNotification(event));

      return;
    }
    if (event.status === ETrainingStatus.FAILED) {
      console.log("FAILED");

      dispatch(setProcessingFilesEnd());
      dispatch(setNotification(event));
      return;
    }
  };
  const onSend = useCallback(
    (message: string) => {
      if (!message) {
        return;
      }

      console.log("onSend");

      const messages = formatMessageForChat({
        message,
        userId,
        profileName: profile?.name,
      });

      const local_time = new Date().toLocaleString("en-US", {
        timeZoneName: "short",
      });

      const payload = {
        user_id: userId,
        query: messages.text,
        request_id: messages.request_id,
        lang_key: "en",
        lang_key_voice: "Geraint",
        memclassset: "other",
        real_voice: "ai4-en-US-Ariana",
        shadowbox: "",
        token: token,
        requestUuid: "test",
        local_time,
      };

      messages.audio = speechModal ? "OK" : undefined;

      //FIXME: this is a hack to fix the bug of the chat
      // pubSubChat.emit(PubSubEvents.USER_SELECT_WORKFLOW, message);

      dispatch(setMessagesChat(messages));
      if (chatState.activateWorkFlow) {
        pubSubChat.emit(PubSubEvents.USER_SELECT_WORKFLOW, message);
        setTimeout(() => {
          const correctUrl = isMessageContainsUrl(message);

          if (chatState.workFlow.id != 4) {
            handlerUserSendWorkFlow(message, correctUrl, messages.request_id);
          } else if (chatState.workFlow.id == 4) {
            handlerUserSendWorkFlow(message, correctUrl, messages.request_id);
          } else {
            setMessageForIncorrectUrl();
          }
        }, 2000);

        return;
      }
      console.log("send_message");

      socket.emit("send_message", payload);
      mayaLoading();

      globalTimeout = setTimeout(() => {
        dispatch(removeLoading());
      }, 30000);
    },
    [socket, chatState, chatState.activateWorkFlow, speechModal, userId]
  );

  const onResend = useCallback(
    (message: ChatMessage) => {
      const payload = {
        user_id: userId,
        query: message.text,
        request_id: message.request_id,
        lang_key: "en",
        lang_key_voice: "Geraint",
        memclassset: "other",
        real_voice: "ai4-en-US-Ariana",
        shadowbox: "",
        token: token,
        requestUuid: "test",
      };

      socket.emit("send_message", payload);
    },
    [socket, userId]
  );

  const renderBubble = (props: any) => {
    return <BubbleRender props={props} />;
  };

  const handleStartRecord = () => {
    setSpeechModal(true);
    setIsFocused(true);
    setIsPlaying(false);
    setHandleVoiceIsRecognizing(true);
    // alert(isPlaying);
    if (!isPlaying) {
      startRecording();
    }
  };

  const sendMessagePressTextAction = (text: string) => {
    onSend((speechModal ? message : text).trim());

    if (Platform.OS === "web") {
      setSpeechModal(false);
      stopRecognizingWeb();
    } else {
      stopRecognition();
      stopRecognitionMobile().then(() => {
        setIsPauseSpeech(false);
        setIsPlaying(true);
        //setSpeechModal(false);
        onResetVoiceMessage();
      });
    }
  };

  const stopSpeech = () => {
    if (Platform.OS === "web") {
      setSpeechModal(false);
      stopRecognizingWeb();
    }
    if (Platform.OS !== "web") {
      // alert("stopSpeech");
      stopRecognition();
      stopRecognitionMobile().then(() => {
        setIsPlaying(false);
        setSpeechModal(false);
        onResetVoiceMessage();
      });
    }
  };

  useEffect(() => {
    const newSocket = webSocket({
      query: {
        user_id: userId,
        token: token?.access_token,
      },
      baseChatUrl,
    });
    setSocket(newSocket);
    newSocket.on("new_message", handleNewMessage);

    newSocket.on("connect_error", handlerErrorConnection);

    newSocket.on("reconnect_failed", handleReconnect);

    newSocket.on("new_answer", handleNewAnswer);

    newSocket.on("training_status", handleEventTraining);

    newSocket.on("disconnect", () => {
      // alert("disconnect");
      // alert(socket.id); // undefined
      // setSocket(null);
    });

    return () => {
      newSocket?.close();
    };
  }, [baseChatUrl, speechModal, userId]);

  // useEffect(() => {
  //   // alert(`speechPause ${speechPause}`);
  //   if (speechPause) {
  //     onSend(message);
  //     setHandleVoiceIsRecognizing(false);
  //     if (recognitionError) {
  //       console.log("ERROR IN RECOGNITION");
  //     }
  //   }
  // }, [speechPause]);

  // useEffect(() => {
  //   if (androidTranscript && speechModal) {
  //     onSend(androidTranscript);
  //     setHandleVoiceIsRecognizing(false);
  //   }
  // }, [androidTranscript, speechModal]);

  useEffect(() => {
    let heightOnSpeech =
      Platform.OS === "ios"
        ? EChatHeightOnOpenActionBanner.IOS_OPEN_SPEECH
        : EChatHeightOnOpenActionBanner.ANDROID_OPEN_SPEECH;
    let heightOffSpeech =
      Platform.OS === "ios"
        ? EChatHeightOnOpenActionBanner.IOS_CLOSE_SPEECH
        : EChatHeightOnOpenActionBanner.ANDROID_CLOSE_SPEECH;
    if (Platform.OS === "web") {
      heightOffSpeech = EChatHeightOnOpenActionBanner.WEB_OPEN_SPEECH;
      heightOnSpeech = EChatHeightOnOpenActionBanner.WEB_CLOSE_SPEECH;
    }

    if (speechModal) {
      setMinInputToolbarHeight(heightOnSpeech);
    } else if (chatState.selectedByUserFlow) {
      setMinInputToolbarHeight(
        Platform.OS !== "web"
          ? EChatHeightOnOpenActionBanner.OPEN_WORK_FLOW
          : EChatHeightOnOpenActionBanner.WEB_OPEN_SPEECH
      );
    } else {
      setMinInputToolbarHeight(heightOffSpeech);
      if (Platform.OS != "web") {
      }
    }
  }, [speechModal, keyboardHeight, chatState.selectedByUserFlow]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        stopSpeech();
      };
    }, [])
  );

  const renderersProps =
    Platform.OS == "ios"
      ? {
          a: {
            onPress(event, url, htmlAttrs, target) {
              navigation.push(RouteNames.WebView2, {
                url,
              });
            },
          },
        }
      : {};

  useEffect(() => {
    if (chatState.workFlow) {
      getTheDoingWorkWithOption(chatState.workFlow.flow)
        .then((workFlow) => {
          dispatch(setWorkOption(workFlow));
          setMessageForWorkFlow(workFlow, chatState.workFlow.name);
        })
        .catch((err) => {
          dispatch(stopWorkFlow());
          setMessageForNotWorkFlow();
          handleQuit(dispatch, () => {
            navigation.navigate(RouteNames.Login);
            alert("Session expired. Please login first");
          });
        });
    }
  }, [chatState.workFlow]);
  const { width } = useWindowDimensions();

  const content = (
    <View style={[styles.containerChat]}>
      {!isFocused && (
        <>
          <View style={styles.imageContainer}>
            <GradientText
              style={{
                fontSize: isWireScreen() ? 44 : 24,
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              How Can I Help You
            </GradientText>

            <View style={[styles.optionsContainer]}>
              <AutoTemplateActionSheet
                handleTemplate={(template) => goChat(template)}
                stylesProps={{
                  backgroundColor: "transparent",
                  borderTopWidth: 0,
                }}
              />
            </View>
          </View>
        </>
      )}

      <GiftedChat
        messages={chatState?.messages || []}
        user={{
          _id: userId,
        }}
        scrollToBottomComponent={() => {
          return (
            <Image
              style={{ width: 16, height: 16 }}
              source={require("../assets/newDesign/arrow-down.png")}
            />
          );
        }}
        renderMessage={(props: MessageProps<ChatMessage>) => {
          let htmlContent = props.currentMessage.text;

          let sourceToken1 = "Opinion  : ";
          if (htmlContent.includes(sourceToken1)) {
            const links = htmlContent.match(/<a\b[^>]*>(.*?)<\/a>/g) || [];
            // alert(links[0]);

            if (links.length >= 1) {
              htmlContent =
                htmlContent.split(sourceToken1)[0] +
                sourceToken1 +
                `<ul style="margin: 0; padding-left: 0; margin-top: 14px; list-style-type: none">${links
                  .map(
                    (link) =>
                      `<li style="margin-bottom: 14px; display: flex; flex-direction: row; width: ${Dimensions.get("window").width - 110}px"><img style="width: 36px; height: 36px; margin-right: 8px; opacity: 0.6" src="${imageSourceResolved.uri}" /><div style="flex: 1;">${link}</div></li>`
                  )
                  .join("")}</ul>
              `;
            }
          }

          let sourceToken3 = "URL  : ";
          if (htmlContent.includes(sourceToken3)) {
            const links = htmlContent.match(/<a\b[^>]*>(.*?)<\/a>/g) || [];
            // alert(links[0]);

            if (links.length >= 1) {
              htmlContent =
                htmlContent.split(sourceToken3)[0] +
                sourceToken3 +
                `<ul style="margin: 0; padding-left: 0; margin-top: 14px; list-style-type: none">${links
                  .map(
                    (link) =>
                      `<li style="margin-bottom: 14px; display: flex; flex-direction: row; width: ${Dimensions.get("window").width - 110}px"><img style="width: 36px; height: 36px; margin-right: 8px; opacity: 0.6" src="${imageSourceResolved.uri}" /><div style="flex: 1;">${link}</div></li>`
                  )
                  .join("")}</ul>
              `;
            }
          }

          let sourceToken4 = "report  : ";
          if (htmlContent.includes(sourceToken4)) {
            const links = htmlContent.match(/<a\b[^>]*>(.*?)<\/a>/g) || [];
            // alert(links[0]);

            if (links.length >= 1) {
              htmlContent =
                htmlContent.split(sourceToken4)[0] +
                sourceToken4 +
                `<ul style="margin: 0; padding-left: 0; margin-top: 14px; list-style-type: none">${links
                  .map(
                    (link) =>
                      `<li style="margin-bottom: 14px; display: flex; flex-direction: row; width: ${Dimensions.get("window").width - 110}px"><img style="width: 36px; height: 36px; margin-right: 8px; opacity: 0.6" src="${imageSourceResolved.uri}" /><div style="flex: 1;">${link}</div></li>`
                  )
                  .join("")}</ul>
              `;
            }
          }

          let sourceToken5 = "Things  : ";
          if (htmlContent.includes(sourceToken5)) {
            const links = htmlContent.match(/<a\b[^>]*>(.*?)<\/a>/g) || [];
            // alert(links[0]);

            if (links.length >= 1) {
              htmlContent =
                htmlContent.split(sourceToken5)[0] +
                sourceToken5 +
                `<ul style="margin: 0; padding-left: 0; margin-top: 14px; list-style-type: none">${links
                  .map(
                    (link) =>
                      `<li style="margin-bottom: 14px; display: flex; flex-direction: row; width: ${Dimensions.get("window").width - 110}px"><img style="width: 36px; height: 36px; margin-right: 8px; opacity: 0.6" src="${imageSourceResolved.uri}" /><div style="flex: 1;">${link}</div></li>`
                  )
                  .join("")}</ul>
              `;
            }
          }

          const sourceToken2 = "<br>Sources: ";
          if (htmlContent.includes(sourceToken2)) {
            const links = htmlContent.match(/<a\b[^>]*>(.*?)<\/a>/g) || [];
            // alert(links[0]);

            if (links.length >= 1) {
              htmlContent =
                htmlContent.split(sourceToken2)[0] +
                "<br/>" +
                sourceToken2 +
                `<ul style="margin: 0; padding-left: 0; margin-top: 14px; list-style-type: none">${links
                  .map(
                    (link) =>
                      `<li style="margin-bottom: 14px; display: flex; flex-direction: row; width: ${Dimensions.get("window").width - 110}px"><img style="width: 36px; height: 36px; margin-right: 8px; opacity: 0.6" src="${imageSourceResolved.uri}" /><div style="flex: 1;">${link}</div></li>`
                  )
                  .join("")}</ul>
              `;
            }
          }

          if (props.currentMessage.user._id == userId) {
            return (
              <View
                style={{
                  width: "100%",
                  flexDirection: "row-reverse",
                  marginBottom: 24,
                }}
              >
                <AvatarChat props={props} userId={userId} />
                <View style={[{ flex: 1, flexDirection: "row" }]}>
                  <View style={{ flex: 1 }}></View>
                  <View style={[styles.messageItemWrap, { marginRight: 12 }]}>
                    <Text style={[styles.messageItemText]}>
                      {props.currentMessage.text}
                    </Text>
                    {/* {props.currentMessage.audio && (
                      <View
                        style={{
                          marginTop: 12,
                          flexDirection: "row",
                          alignItems: "center",
                          width: 250,
                        }}
                      >
                        <Text style={{ color: "#FFFFFF" }}>02:12</Text>
                        <View
                          style={{
                            height: 44,
                            paddingVertical: 8,
                            paddingHorizontal: 12,
                            backgroundColor: "#FFFFFF",
                            borderRadius: 8,
                            marginLeft: 10,
                            flex: 1,
                          }}
                        >
                          <Image
                            source={require("../assets/newDesign/voice.png")}
                          />
                        </View>
                      </View>
                    )} */}

                    <Text
                      style={[
                        styles.messageItemText,
                        {
                          textAlign: "right",
                          marginTop: 12,
                        },
                      ]}
                    >
                      {dayjs(props?.currentMessage?.createdAt).format("HH:mm")}
                    </Text>
                  </View>
                </View>
              </View>
            );
          } else if (props.currentMessage.user._id == UUID) {
            return (
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginBottom: 24,
                }}
              >
                <AvatarChat props={props} userId={userId} />
                <View style={{ flex: 1 }}>
                  <View
                    style={[
                      styles.messageItemWrap,
                      {
                        backgroundColor: "#F3F3F3",
                        marginLeft: 12,
                      },
                    ]}
                  >
                    {props.currentMessage.loading ? (
                      <Loading />
                    ) : (
                      <>
                        {props.currentMessage.text.includes(sourceToken1) ||
                        props.currentMessage.text.includes(sourceToken2) ||
                        props.currentMessage.text.includes(sourceToken3) ||
                        props.currentMessage.text.includes(sourceToken4) ||
                        props.currentMessage.text.includes(sourceToken5) ? (
                          <RenderHTML
                            renderersProps={renderersProps}
                            contentWidth={width}
                            source={{
                              html: `<div style="color: #2F323F;text-align: start;font-size: 14px;">${htmlContent.replace(/#00FFA2/g, "#0050FF")}</div>`,
                            }}
                          />
                        ) : (
                          <Markdown
                            style={{
                              text: {
                                color: "#2F323F",
                                fontSize: 14,
                              },
                            }}
                          >
                            {props.currentMessage.text.replace(
                              /#00FFA2/g,
                              "#0050FF"
                            )}
                          </Markdown>
                        )}

                        <Text
                          style={[
                            styles.messageItemText,
                            { color: "#2F323F", marginTop: 12 },
                          ]}
                        >
                          {dayjs(props?.currentMessage?.createdAt).format(
                            "HH:mm"
                          )}
                        </Text>
                      </>
                    )}
                  </View>
                  {!props.currentMessage.loading && (
                    <View
                      style={{
                        marginLeft: 12,
                        marginTop: 12,
                        flexDirection: "row",
                      }}
                    >
                      <TouchableOpacity
                        style={[styles.messageAction]}
                        onPress={() => {
                          Clipboard.setString(props.currentMessage.text);
                        }}
                      >
                        <Image
                          style={styles.messageActionImage}
                          source={require("../assets/newDesign/copy.png")}
                        />
                        <Text style={styles.messageActionText}>Copy</Text>
                      </TouchableOpacity>

                      {/* <TouchableOpacity
                    style={[styles.messageAction]}
                    onPress={() => {
                      // handle resend old message
                      const originQuestion = (
                        chatState?.messages || []
                      ).find((item) => {
                        return (
                          item.request_id ===
                          props.currentMessage.request_id &&
                          item.user._id === userId
                        );
                      });
                      if (originQuestion) {
                        onResend(originQuestion);
                      } else {
                        console.log("originQuestion not found");
                      }
                    }}
                  >
                    <Image
                      style={styles.messageActionImage}
                      source={require("../assets/newDesign/backward.png")}
                    />
                    <Text style={styles.messageActionText}>
                      Reproduce the answer
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={[
                      styles.messageAction,
                      props.currentMessage.isHeart && {
                        backgroundColor: "#ffbaba",
                      },
                    ]}
                    onPress={() => {
                    }}
                  >
                    <Image
                      style={styles.messageActionImage}
                      source={require("../assets/newDesign/heath.png")}
                    />
                  </TouchableOpacity> */}
                    </View>
                  )}
                </View>
              </View>
            );
          }
        }}
        alwaysShowSend={true}
        showUserAvatar
        scrollToBottom={true}
        showAvatarForEveryMessage={true}
        keyboardShouldPersistTaps={"never"}
        messagesContainerStyle={{
          bottom: speechModal ? 90 : 10,
          width: "100%",
          display: "flex",
          alignSelf: "center",

          ...(isFocused
            ? {}
            : {
                height: 0,
                marginBottom: 120,
              }),
        }}
        renderAvatar={(props) => {
          return <AvatarChat props={props} userId={userId} />;
        }}
        renderInputToolbar={(props) => {
          return (
            <View
              style={{
                // height: 54,
                position: "absolute",
                bottom: (Platform.OS === "ios" ? keyboardHeight : 0) - 10,
                width: "100%",
                backgroundColor: "white",
                paddingBottom: Platform.OS == "ios" ? 24 : 14,
              }}
            >
              <CustomChatInput
                voiceAmplitude={voiceAmplitude}
                isPlaying={isPlaying}
                message={message}
                props={props}
                stopSpeech={() => {
                  stopSpeech();
                  setIsPauseSpeech(false);
                  setIsPlaying(false);
                }}
                speechModal={speechModal}
                sendFn={(text) => sendMessagePressTextAction(text)}
                attachFn={pickDocument}
                startRecord={handleStartRecord}
                onFocus={() => {
                  setIsFocused(true);
                }}
                isPauseSpeech={isPauseSpeech}
                togglePauseSpeech={() => {
                  const newValue = !isPauseSpeech;
                  setIsPauseSpeech(newValue);

                  if (newValue) {
                    if (Platform.OS === "web") {
                      stopRecognizingWeb();
                    } else {
                      stopRecognition();
                      stopRecognitionMobile().then(() => {});
                    }
                  } else {
                    setHandleVoiceIsRecognizing(true);
                    if (!isPlaying) {
                      if (Platform.OS != "web") {
                        startRecognitionMobile(true, message).catch(
                          console.error
                        );
                      } else {
                        startRecognitionWeb();
                      }
                    }
                  }
                }}
              />
            </View>
          );
        }}
        textInputRef={textInputRef}
        renderAvatarOnTop={false}
      />
    </View>
  );

  return (
    <WrapperView>
      <View
        style={[
          dimentionState && !dimentionState.mobile
            ? styles.containerOnWeb
            : styles.container,
        ]}
      >
        {isPlaying && soundObject._loaded && (
          <Pressable
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
            onPress={() => {
              handleStop(soundObject);
            }}
          ></Pressable>
        )}

        {content}
      </View>
    </WrapperView>
    //###############################################################################
  );
};

export default ChatHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  containerOnWeb: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: colors.background,
  },
  containerChat: {
    flex: 1,
    width: "100%",
    // borderRightColor: colors.borderColor,
    // borderWidth: 0.2,
    borderBottomColor: "#FFFFFF33",
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    backgroundColor: "#273236",
    paddingHorizontal: 10,
    flex: 1,
    color: "#fff",
  },
  Sendbutton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 40,
    position: "absolute",
    left: -50,
    bottom: 10,
  },
  image: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
  },
  paperClip: {
    marginTop: 8,
    marginHorizontal: 5,
    transform: [{ rotateY: "180deg" }],
  },
  sendButton: { marginBottom: 10, marginRight: 10 },
  sendContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  fileContainer: {
    flex: 1,
    maxWidth: 300,
    marginVertical: 2,
    borderRadius: 15,
  },
  fileText: {
    marginVertical: 5,
    fontSize: 16,
    lineHeight: 20,
    marginLeft: 10,
    marginRight: 5,
  },

  buttonFooterChatImg: {
    width: 35,
    height: 35,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderColor: "black",
    left: 66,
    top: -4,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
  },
  imageContainer: {
    // height: 10,
    // alignSelf: "center",
    // backgroundColor: "white",
    // bottom: 480,
    // overflow: "hidden",
    // width: "100%",
    paddingTop: 200,
  },
  imageBot: {
    alignSelf: "center",
    width: 200,
    height: 200,
  },
  textContainer: {
    justifyContent: "center",
    alignItems: "center",
    top: "14%",
  },
  title: {
    fontSize: 24,
    color: "black",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  optionsContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    width: "100%",
    marginTop: 200,
    // height: 200,
  },
  option: {
    fontFamily: "Montserrat",
    fontSize: 18,
    color: "white",
    fontWeight: "500",
    paddingBottom: 20,
  },
  buttonWrapper: {
    width: 233,
    alignSelf: "center",
    marginTop: 30,
  },

  messageItemWrap: {
    backgroundColor: "#0050FF",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 12,
  },
  messageItemText: { color: "white", fontSize: 14 },
  messageAction: {
    backgroundColor: "#F6F6F6",
    paddingHorizontal: 8,
    borderRadius: 30,

    flexDirection: "row",
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
  },
  messageActionText: {
    fontSize: 12,
    color: "#2F323F",
    marginLeft: 8,
  },
  messageActionImage: {
    width: 16,
    height: 16,
  },
});
