import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Alert,
  Button,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { colors } from "../../helpers/colors";
import { useKeyboard } from "../../hooks/useKeyboard";
import AutoTemplateActionSheet from "../AutoTemplateActionSheet/AutoTemplateActionSheet";
import { useSelector } from "react-redux";
import { RootState } from "../../../types";
import { dispatch } from "../../store/store";
import { setPromptMessage } from "../../reducers/chatSlice";
import StopStepBackWorkFlow from "../StopStepBackActions/StopStepBackWorkFlow";
import { stopProcessWorkFlow } from "../../../src/services/chat.service";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import TextSpeechActions, {
  TextSpeechActionsProps,
} from "../TextSpeechActions/TextSpeechActions";
import { Colors } from "react-native/Libraries/NewAppScreen";
import ButtonWide from "../general/ButtonWide";
import SoundWave from "../Animations/ListeningWave";
import { ListeningWave2 } from "../Animations/ListeningWave2";
import { isWireScreen } from "../../helpers/utils";
import Modal from "react-native-modal";
import { RouteNames } from "../../navigation/routeNames";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import TraningListHOC from "../../containers/TrainingListHOC";

type CustomChatInputProps = {
  props: any;
  sendFn: (text: any) => void;
  attachFn: () => void;
  startRecord?: () => void;
  trainingOptions?: boolean;
  pressForClose?: boolean;
  onFocus?: () => void; // Add onFocus prop
  speechModal: boolean;
  stopSpeech: () => void;
  togglePauseSpeech: () => void;
  isPauseSpeech: boolean;
  message: string;
  isPlaying: boolean;
  voiceAmplitude: number;
};

const CustomChatInput = ({
  attachFn,
  props,
  sendFn,
  startRecord,
  speechModal,
  stopSpeech,
  trainingOptions = false,
  pressForClose = false,
  isPauseSpeech = false,
  onFocus,
  togglePauseSpeech,
  message,
  isPlaying,
  voiceAmplitude = 0,
}: CustomChatInputProps) => {
  const [textInput, setTextInput] = useState("");
  const [workFlowView, setWorkFlowView] = useState(null);
  const [closeDag, setCloseDag] = useState(false);
  const { selectedByUserFlow, activateWorkFlow, workFlow } = useSelector(
    (state: RootState) => state.persist.chatReducer
  );
  const [activateSuggestion, setActivateSuggestion] = useState(true);

  const [needClear, setNeedClear] = useState(0);

  const keyboardHeight = useKeyboard();

  const [state, setState] = useState({
    showTraining: false,
  });

  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  useEffect(() => {
    if (selectedByUserFlow != undefined) {
      setWorkFlowView(true);
      console.log("selected by user flow" + selectedByUserFlow);
    } else {
      console.log("selected by user flow" + selectedByUserFlow);

      setWorkFlowView(false);
    }
    console.log("from chat input " + workFlow);
  }, [selectedByUserFlow]);

  const handleSend = () => {
    console.log("handle send");

    if ((textInput && textInput != "#") || speechModal) {
      sendFn(textInput);
      setTimeout(() => {
        setTextInput("");
      }, 0);
      setNeedClear(Math.random());
    }
  };

  const handleStop = () => {
    setCloseDag(true);
    stopProcessWorkFlow(dispatch, "stop");
    // setWorkFlowView(false);
    setTextInput(""); // Clear text input when stopping workflow
  };

  const containsHashtag = (text: string) => {
    const hashtagRegex = "#";
    return hashtagRegex == text;
  };

  const handleTypeTextChange = (text: string) => {
    setCloseDag(false);
    if (!workFlow && containsHashtag(text)) {
      setActivateSuggestion(true);
      Keyboard.dismiss();
    } else {
      setActivateSuggestion(false);
    }
    setTextInput(text);
  };

  const handlerTemplateOnText = (template: string) => {
    setActivateSuggestion(false);
    setTextInput("");
    dispatch(setPromptMessage(null));
    if (Platform.OS == "ios") {
      Keyboard.dismiss();
    }
  };

  useEffect(() => {
    if (trainingOptions) {
      setActivateSuggestion(true);
    } else {
      setActivateSuggestion(false);
    }
  }, [trainingOptions]);

  const closeButton = useMemo(
    () =>
      workFlowView && (
        <View
          style={[
            {
              right: 4,
            },
          ]}
        >
          <Pressable onPress={handleStop}>
            <Image
              style={{
                width: Platform.OS === "web" ? 34 : 24,
                height: Platform.OS === "web" ? 34 : 24,
              }}
              source={require("../../assets/x_close.png")}
            />
          </Pressable>
        </View>
      ),
    [workFlowView]
  );

  const textScrollRef = useRef<ScrollView>();

  useEffect(() => {
    if (textScrollRef.current) {
      textScrollRef.current.scrollToEnd({ animated: true });
    }
  }, [message, speechModal]);

  const goBack = useCallback(() => {
    workFlow && handleStop();
    speechModal && stopSpeech();
    setTextInput("");
    // setNeedClear(true);
  }, [workFlow, speechModal]);

  if (speechModal)
    return (
      <View style={[speechStyles.container]}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            // paddingLeft: 8,
            maxWidth: "50%",

            marginBottom: 24,
          }}
        >
          <Pressable
            style={{ marginRight: 10 }}
            onPress={() => {
              togglePauseSpeech();
            }}
          >
            <View>
              {isPauseSpeech || isPlaying ? (
                <Image
                  style={[
                    { width: 32, height: 32 },
                    isPlaying && { opacity: 0.3 },
                  ]}
                  source={require("../../assets/newDesign/play_btn.png")}
                />
              ) : (
                <Image
                  style={{ width: 32, height: 32 }}
                  source={require("../../assets/newDesign/pause-circle.png")}
                />
              )}
            </View>
          </Pressable>
          <ListeningWave2
            voiceAmplitude={voiceAmplitude}
            pause={isPauseSpeech || isPlaying}
            needClear={needClear}
          />
        </View>
        {message && (
          <View style={{ marginBottom: 24 }}>
            <ScrollView style={{ maxHeight: 72 }} ref={textScrollRef}>
              <Text style={{ fontSize: 14, fontWeight: "400", lineHeight: 24 }}>
                {message}
              </Text>
            </ScrollView>
          </View>
        )}

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Pressable onPress={goBack} style={{ flexDirection: "row" }}>
            <Image
              style={{
                width: Platform.OS == "web" ? 34 : 16,
                height: Platform.OS == "web" ? 34 : 16,
                marginRight: 4,
              }}
              source={require("../../assets/newDesign/arrow-left2.png")}
            />
            <Text
              style={[
                speechStyles.backText,
                Platform.OS === "web" && {
                  fontSize: 16,
                  paddingTop: 8,
                },
              ]}
            >
              Back
            </Text>
          </Pressable>
          <ButtonWide
            style={{
              width: 80,
              height: 44,
            }}
            styleText={{
              fontSize: 14,
            }}
            onPress={handleSend}
            text="Send"
            disabled={!(textInput || (speechModal && message))}
          />
        </View>
      </View>
    );

  return (
    <>
      <Modal
        isVisible={state.showTraining}
        onBackdropPress={() => {
          setState({ ...state, showTraining: false });
        }}
      >
        <View style={{ alignItems: "center", width: 690, alignSelf: "center" }}>
          {/* <Text>Hello!</Text>
          <Button
            title="Hide Modal"
            onPress={() => setState({ ...state, showTraining: false })}
          /> */}
          <KeyboardAvoidingView
            style={{ height: "100%" }}
            keyboardVerticalOffset={-160}
            behavior={"position"}
          >
            <View
              style={{
                height: "100%",
                borderWidth: 1,
                borderRadius: 20,
                overflow: "hidden",
                paddingHorizontal: 114,
                paddingTop: 40,
                paddingBottom: 40,
                backgroundColor: "white",
              }}
            >
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "700",
                  color: "#131418",
                  textAlign: "center",
                }}
              >
                Learn From
              </Text>

              <TraningListHOC
                onStartTraining={() => {
                  setState({ ...state, showTraining: false });
                }}
              />
            </View>
          </KeyboardAvoidingView>
        </View>
      </Modal>

      {activateSuggestion && Platform.OS != "web" && (
        <View
          style={{
            flex: 1,
            backgroundColor: "white",
          }}
        >
          <AutoTemplateActionSheet
            handleTemplate={handlerTemplateOnText}
            stylesProps={{ backgroundColor: "white" }}
          />
        </View>
      )}
      {activateSuggestion && Platform.OS == "web" && (
        <AutoTemplateActionSheet
          handleTemplate={handlerTemplateOnText}
          stylesProps={{ bottom: "30%", flex: 1 }}
        />
      )}

      <View {...props} style={[styles.container]}>
        <KeyboardAvoidingView
          keyboardVerticalOffset={Platform.OS === "android" ? 60 : 40}
          behavior={"position"}
        >
          <View style={styles.actionContainer}>
            <View style={styles.btnGroupLeft}>
              <Pressable
                onPress={() => {
                  if (isWireScreen()) {
                    setState({ ...state, showTraining: true });
                  } else {
                    navigation.navigate(RouteNames.TrainingList);
                  }
                }}
                style={[, { marginRight: 8 }]}
              >
                <Image
                  style={{
                    width: Platform.OS == "web" ? 34 : 24,
                    height: Platform.OS == "web" ? 34 : 24,
                  }}
                  source={require("../../assets/newDesign/fileUpload.png")}
                />
              </Pressable>

              <Pressable onPress={startRecord}>
                <Image
                  style={{
                    width: Platform.OS == "web" ? 34 : 24,
                    height: Platform.OS == "web" ? 34 : 24,
                  }}
                  source={require("../../assets/newDesign/microphone.png")}
                />
              </Pressable>
            </View>

            <View
              style={[
                styles.textInputWrapper,
                // textInput || speechModal ? { width: "78%" } : {},
              ]}
            >
              <TextInput
                placeholder="Type something"
                placeholderTextColor="#A9ACB2"
                autoCapitalize="none"
                multiline={true}
                style={[
                  styles.textInput,
                  Platform.OS === "web" && {
                    padding: 10,
                    height: "80%",
                  },

                  workFlow && {
                    paddingRight: 50,
                  },
                ]}
                value={textInput}
                onChangeText={handleTypeTextChange}
                onSubmitEditing={handleSend}
                autoFocus={false}
                onFocus={onFocus}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && Platform.OS === "web") {
                    handleSend();
                  }
                }}
              />

              <View
                style={[
                  {
                    right: workFlow && Platform.OS !== "web" ? 42 : 12,
                    flexDirection: "row",
                  },
                ]}
              >
                {workFlow && (
                  <View style={[styles.btnGroupLeft]}>
                    <Pressable
                      style={[
                        isWireScreen() && {
                          paddingTop: 10,
                        },
                      ]}
                      onPress={goBack}
                    >
                      <Image
                        style={{ width: 24, height: 24 }}
                        source={require("../../assets/newDesign/close-circle-bold.png")}
                      />
                    </Pressable>
                  </View>
                )}

                <Pressable onPress={handleSend}>
                  {textInput || (speechModal && message)
                    ? (isWireScreen() && (
                        <View
                          style={{
                            backgroundColor: "#0050FF",
                            borderRadius: 8,
                            marginRight: -8,
                          }}
                        >
                          <Text
                            style={{
                              width: 77,
                              paddingHorizontal: 16,
                              paddingVertical: 12,
                              textAlign: "center",
                              color: "#FFFFFF",
                              fontWeight: "600",
                            }}
                          >
                            Send
                          </Text>
                        </View>
                      )) || (
                        <Image
                          style={{
                            width: Platform.OS == "web" ? 34 : 24,
                            height: Platform.OS == "web" ? 34 : 24,
                          }}
                          source={require("../../assets/newDesign/sendArrowActive.png")}
                        />
                      )
                    : (isWireScreen() && (
                        <View
                          style={{
                            backgroundColor: "#D4D4D4",
                            borderRadius: 8,
                            marginRight: -8,
                          }}
                        >
                          <Text
                            style={{
                              width: 77,
                              paddingHorizontal: 16,
                              paddingVertical: 12,
                              textAlign: "center",
                              color: "#5F626E",
                              fontWeight: "600",
                            }}
                          >
                            Send
                          </Text>
                        </View>
                      )) || (
                        <Image
                          style={{
                            width: Platform.OS == "web" ? 34 : 24,
                            height: Platform.OS == "web" ? 34 : 24,
                          }}
                          source={require("../../assets/newDesign/sendArrow.png")}
                        />
                      )}
                </Pressable>
              </View>
            </View>
          </View>
        </KeyboardAvoidingView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    // height: 300,
    // position: "absolute",
    // bottom: 20,

    bottom: 0,
    paddingTop: 12,
  },
  actionContainer: {
    flexDirection: "row",
    alignItems: "center",
    // alignContent: "center",
    //paddingHorizontal: 5,
    // justifyContent: Platform.OS === "web" ? "center" : "space-evenly",
    width: "100%",
    // bottom: 40,

    // borderWidth: 1,
  },

  textInputWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 12,
    backgroundColor: "#F3F3F3",
    // width: "70%",
    height: 50,
    paddingVertical: 0,
    justifyContent: "space-between",
    flex: 1,
  },
  textInput: {
    color: "black",
    fontSize: 14,
    outlineWidth: 0,
    //outlineStyle:Platform.OS=='web'&& 'none',
    paddingVertical: 4,
    paddingHorizontal: 12,
    width: "88%",
  },
  chipWrapper: {
    borderRadius: 38,
    borderWidth: 1,
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginHorizontal: 30,
    minWidth: 80,
    margin: 10,
    alignItems: "center",
  },

  btnGroupLeft: {
    width: "auto",
    flexDirection: "row",
    justifyContent: "center",
    // marginHorizontal: 22,
    // borderWidth: 1,
    marginRight: 16,
  },
});

const speechStyles = StyleSheet.create({
  container: {
    flexDirection: "column",

    bottom: 0,

    borderWidth: 1,
    borderColor: "#D4D4D4",
    paddingHorizontal: 12,
    paddingVertical: 16,
    backgroundColor: "#F6F6F6",
    borderRadius: 12,
  },
  backText: {
    fontSize: 14,
    fontWeight: "600",
    color: "#2F323F",
  },
});

export default CustomChatInput;
