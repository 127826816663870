export const colors = {
  background: "white",
  borderColor: "#FFFFFF33",
  linear: ["#267F85", "#EAC951"],
  skyBlue: "#6DD5ED",
  springGreen: "#00FFA2",
  electricViolet: "#992FFA",
  sunShade: "#FF9B2F",
  anakiwa: "#A0BEFF",
  mineshaft: "#333333",
  bubble: "#FFFFFF26",
  placeholder: "#A9ACB2",
  background2: "#FFFFFF1A",
  red: "#FF0000",
  black: "#000000",
  primaryBlue: "#0050FF",
  secondaryGray: "#F6F6F6",
};
