import React, { useEffect } from "react";
import WebViewComponent from "../components/web-view/WebViewComponent";

const WebViewHOC = ({ route }) => {
  const { url } = route?.params ?? {};

  return <WebViewComponent url={url} />;
};

export default WebViewHOC;
