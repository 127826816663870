import React from "react";
import { Image, Pressable, StyleSheet, View, Text } from "react-native";

// Components
import MayaText from "../typography/MayaText";

// Constants

type Props = {
  title: string;
  index: number;
  onPress: () => void;
  image: any;
  isSelected?: boolean;
};

const ListItem = ({
  title,
  index,
  onPress,
  image,
  isSelected = false,
}: Props) => {
  return (
    <Pressable
      style={[styles.container, isSelected && styles.selectedContainer]}
      onPress={onPress}
    >
      <View
        style={[styles.imageWrapper, isSelected && styles.selectedImageWrapper]}
      >
        <Image source={image} resizeMode="contain" style={styles.image} />
      </View>
      <Text style={[styles.text, isSelected && styles.selectedText]}>
        {title}
      </Text>
    </Pressable>
  );
};

export default ListItem;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#F6F6F6",
    flex: 1,
    paddingTop: 32,
    paddingRight: 0,
    paddingBottom: 32,
    paddingLeft: 0,
    borderRadius: 20,
    height: 156,

    // justifyContent: "space-between",
    marginHorizontal: 8,
    alignItems: "center",
  },
  selectedImageWrapper: {
    backgroundColor: "#0050FF",
  },
  selectedContainer: {
    backgroundColor: "#EDF3FF",
  },
  imageWrapper: {
    width: 48,
    height: 48,
    borderRadius: 40,
    backgroundColor: "#D4D4D4",
  },
  image: {
    width: 24,
    height: 24,
    top: 12,
    left: 12,
  },
  selectedText: {
    fontWeight: "700",
    color: "#131418",
  },
  text: {
    fontSize: 16,
    color: "#5F626E",
    lineHeight: 28,
    marginTop: 16,
    fontWeight: "400",
  },
});
