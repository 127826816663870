import { createSlice } from "@reduxjs/toolkit"

export type DimentionState = {
    mobile: boolean
}

const initialState: DimentionState = {
    //OJO WAS CHANGED FROM FALSE 
    mobile: true
}

const dimentionSlice = createSlice({
    name: 'dimention_slice',
    initialState,
    reducers: {
        setMobile: (state, action) => {
            state.mobile = action.payload
        }
    }
})

export const { setMobile } = dimentionSlice.actions

export default dimentionSlice.reducer;