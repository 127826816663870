export const RouteNames = {
  Home: "Home",
  Splash: "Splash",
  Login: "Login",
  Register: "Register",
  Prompt: "PromptHOC",
  Pay: "Payment",
  Plan: "Plans",
  Promt: "PromptHOC",

  TopRegisterBar: "TopRegisterBar",
  TopBar: "TopBar",

  Activation: "Activate",
  ForgotPassword: "ForgotPassword",
  ResetPassword: "ResetPassword",
  WriteSomethingList: "Generate Insight",
  TrainingList: "Learn From",
  Loading: "Loading",
  Chat: "Chat",
  SettingsMenuHoc: "Setting",
  SingleInputScreen: "SingleInputScreen",
  Works: "Works",
  WebView2: "WebView2",
  Settings: "Settings",
  Speech: "Speech",

  // Works
  WorklistHOC: "WorklistHOC",
  Folders: "Folders",
  Chats: "Chats",
  Training: "Training",
  FolderScreen: "FolderScreen",

  // Training
  LiveLearning: "Live Learning",
  URL: "URL",
  // PDF: "PDF",
  DOCUMENT: "DOCUMENT",

  DocumentManagement: "Document Management",

  DOC: "DOC",
  CSV: "CSV",
  Text: "Text",

  // Settings

  Profile: "User Profile",
  Payment: "Payment",
  Passcode: "Passcode",
  PasscodeEdit: "Changing password",
  Notifications: "Notifications",
  GeneralSettings: "GeneralSettings",
  BusinessInfo: "BusinessInfo",
};
