import {
  collection,
  addDoc,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../infra/firebase";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  HistoryNotification,
  TrainingEvent,
} from "../../models/trainingEvent.model";
export const getNotification = async (userId: string) => {
  try {
    // console.log("getNotification userId: ", userId);
    const userNotification = await getDocs(
      collection(db, "training", ...[userId, "History"])
    ).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...(doc.data() as HistoryNotification),
        id: doc.id,
      }));
      if (!newData || newData.length === 0) {
        return [];
      }
      const filterData = newData.filter((item) => item.status === "success");
      if (!filterData || filterData.length === 0) {
        return [];
      }
      return filterData;
    });
    return userNotification;
  } catch (e) {
    console.error("Error adding document: ", e);
    return [];
  }
};

export const updateReadStatus = async (userId: string, requestId: string) => {
  try {
    const notificationDoc = doc(
      db,
      ...["training", userId, "History", requestId]
    );
    const notification = await getDocs(
      collection(db, "training", ...[userId, "History"])
    );
    const data = notification.docs
      .filter((doc) => {
        const data_ = doc.data() as HistoryNotification;
        return data_.request_id === requestId;
      })
      .map((history) => history.data());

    if (!data) {
      return null;
    }

    const updatedValues = data.map((item: TrainingEvent) => {
      return { ...item, read: true };
    });

    if (updatedValues[0]) await updateDoc(notificationDoc, updatedValues[0]);

    return { message: "success", status: "success", requestId };
  } catch (e) {
    console.error("Error updateReadStatus: ", e);
  }
};

export const getNotificationAsyncThunk = createAsyncThunk(
  "notification/getNotification",
  async (data: { userId: string }) => {
    const response = await getNotification(data.userId);
    return response;
  }
);

export const updateReadStatusAsyncThunk = createAsyncThunk(
  "notification/updateReadStatus",
  async (data: { userId: string; requestId: string }) => {
    if (!data.requestId) return data;

    const response = await updateReadStatus(data.userId, data.requestId);
    return response;
  }
);
