import React from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import { colors } from "../../helpers/colors";

type Props = {
  isVisible: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
};

const MenuModal = ({ isVisible, closeModal, children }: Props) => {
  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      useNativeDriverForBackdrop
      animationIn={"slideInRight"}
      animationOut={"slideOutRight"}
      backdropOpacity={0.7}
      backdropColor={"#000"}
      style={{
        margin: 0,
        justifyContent: "flex-end",
        zIndex: 1,
      }}
    >
      <View
        style={[
          styles.modalContainer,
          Platform.OS === "android" && styles.androidModalContainer,
        ]}
      >
        {children}
      </View>
    </Modal>
  );
};

export default MenuModal;

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: "#F3F3F3",
    // paddingHorizontal: "1%",
    flexDirection: "column",
    // justifyContent: "center",
    alignSelf: "flex-end",
    // marginTop: Dimensions.get("window").width * 0.3,
    flex: 1,

    paddingTop: 60,
    paddingHorizontal: 16,

    width: Dimensions.get("window").width * 0.73,
    maxWidth: 600,
  },
  androidModalContainer: {
    // Add Android-specific styling here
    paddingTop: 20, // Example adjustment
  },
});
