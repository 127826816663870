import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  View,
  Text,
} from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";
import ButtonWide from "../components/general/ButtonWide";
import DatePicker from "react-native-date-picker";
import { WrapperView } from "../components/Layout/WrapperView";
import { GradientBox } from "../components/typography/GradientBox";
import { isWireScreen } from "../helpers/utils";

const ProfileHOC = ({}) => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const [open, setOpen] = useState(false);

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
  };

  const pressSave = () => {
    dispatch(updateUserProfileAsyncThunk(profile as UserModel));
  };

  const birthdayRef = useRef<TextInput>();
  let birthday = new Date();
  if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(profile?.birthday || "")) {
    const birthdayParts = profile?.birthday.split("/");
    birthday = new Date();
    birthday.setFullYear(parseInt(birthdayParts[2]));
    birthday.setDate(parseInt(birthdayParts[0]));
    birthday.setMonth(parseInt(birthdayParts[1]) - 1);
  }

  return (
    <View style={{ backgroundColor: "white" }}>
      <View style={styles.sectionContainer}>
        <ProfileField
          label={"Name"}
          attr="firstname"
          value={profile?.firstname}
          onPress={onPress}
        />

        <ProfileField
          label={"Surname"}
          value={profile?.lastname}
          attr="lastname"
          onPress={onPress}
        />

        <ProfileField
          label={"Email"}
          value={profile?.email}
          enableArrow={false}
          attr="email"
          onPress={onPress}
        />

        <ProfileField
          label={"Birthday"}
          onFocus={() => {
            setOpen(true);
          }}
          ref2={birthdayRef}
          value={profile?.birthday}
          enableArrow={false}
          attr="birthday"
          onPress={onPress}
        />

        {Platform.OS !== "web" && (
          <DatePicker
            modal
            mode="date"
            open={open}
            date={birthday}
            onConfirm={(date) => {
              onPress("birthday", date.toLocaleDateString("en-GB"));
              setOpen(false);
              birthdayRef.current.blur();
            }}
            onCancel={() => {
              setOpen(false);
              birthdayRef.current?.blur();
            }}
          />
        )}
        <ButtonWide
          text={"Save"}
          onPress={pressSave}
          style={[
            isWireScreen() && {
              width: 180,
            },
          ]}
          // loading={loadingUrl}
          // disabled={loadingUrl || !newValue}
        />
      </View>
    </View>
  );
};

export default ProfileHOC;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    padding: 20,
  },
  editButton: {
    alignSelf: "center",
    fontWeight: "600",
    color: colors.anakiwa,
  },
  sectionContainer: {
    // padding: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.background2,
    borderRadius: 30,
    width: "100%",
    alignSelf: "flex-start",
  },
  title: {
    fontWeight: "600",
    fontSize: 10,
    marginBottom: 12,
  },
});
