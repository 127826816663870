import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  ScrollView,
  StyleSheet,
  View,
  Text,
} from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  changePassAsyncThunk,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";
import ButtonWide from "../components/general/ButtonWide";
import { WrapperView } from "../components/Layout/WrapperView";
import { isWireScreen } from "../helpers/utils";
import { GradientButton } from "../components/typography/GradientButton";
import Modal from "react-native-modal";
import { validatePassword } from "./PasscodeEditHOC";

const PasscodeHOC = () => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  const [state, setState] = useState({
    showChangePasswordPopup: false,

    oldPass: "",
    newPass: "",
    confirmNewPass: "",
  });

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
  };

  const pressSave = () => {
    if (state.confirmNewPass !== state.newPass) {
      alert("New password and confirmation do not match");
      return;
    }

    const errorMessages = validatePassword(state.newPass);
    if (errorMessages.length > 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    dispatch(
      changePassAsyncThunk({
        oldPass: state.oldPass,
        newPass: state.newPass,
        nav: navigation,
      })
    );
  };

  useEffect(() => {
    dispatch(getUserAsyncThunk());
  }, []);
  return (
    <ScrollView style={{ height: "100%", backgroundColor: "white" }}>
      <View style={styles.sectionContainer}>
        {/* <MayaText style={styles.title}>ACCOUNT INFORMATION</MayaText> */}
        {(isWireScreen() && (
          <>
            {/* <Modal isVisible={state.showChangePasswordPopup}>
              <View style={{}}>
                <Text>Hello!</Text>
                <Button
                  title="Hide Modal"
                  onPress={() =>
                    setState({ ...state, showChangePasswordPopup: false })
                  }
                />
              </View>
            </Modal> */}
            {(state.showChangePasswordPopup && (
              <>
                <View style={{}}>
                  <ProfileField
                    isPassword={true}
                    label={"Old password"}
                    value={state.oldPass}
                    attr="oldPass"
                    onPress={(_, newValue) =>
                      setState({ ...state, oldPass: newValue })
                    }
                    enableArrow={false}
                  />

                  <ProfileField
                    isPassword={true}
                    label={"New password"}
                    value={state.newPass}
                    attr="newPass"
                    onPress={(_, newValue) =>
                      setState({ ...state, newPass: newValue })
                    }
                    enableArrow={false}
                  />

                  <ProfileField
                    isPassword={true}
                    label={"Confirm new password"}
                    value={state.confirmNewPass}
                    attr="confirmNewPass"
                    onPress={(_, newValue) =>
                      setState({ ...state, confirmNewPass: newValue })
                    }
                    enableArrow={false}
                  />

                  <ButtonWide
                    text={"Save"}
                    onPress={pressSave}
                    style={{ width: 180 }}
                  />
                </View>
              </>
            )) || (
              <>
                <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                  <ProfileField
                    isPassword={false}
                    label={"Password"}
                    value={"******"}
                    attr="password"
                    onPress={() => {}}
                    enableArrow={false}
                    style={{ flex: 1, marginRight: 20 }}
                  />
                  <GradientButton
                    onPress={() => {
                      setState({ ...state, showChangePasswordPopup: true });
                    }}
                    style={{ width: 180, marginBottom: 28, borderWidth: 1 }}
                  >
                    Change password
                  </GradientButton>
                </View>
              </>
            )}
          </>
        )) || (
          <>
            <ProfileField
              isPassword={false}
              label={"Password"}
              value={"******"}
              attr="password"
              onPress={() => {}}
              enableArrow={false}
            />
            <ButtonWide
              text={"Change password"}
              onPress={() => {
                navigation.navigate(RouteNames.PasscodeEdit);
              }}
            />
          </>
        )}
      </View>
    </ScrollView>
  );
};

export default PasscodeHOC;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    padding: 20,
  },
  editButton: {
    alignSelf: "center",
    fontWeight: "600",
    color: colors.anakiwa,
  },
  sectionContainer: {
    // padding: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.background2,
    width: "100%",
    alignSelf: "flex-start",
  },
  title: {
    fontWeight: "600",
    fontSize: 10,
    marginBottom: 12,
  },
});
