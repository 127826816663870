import React from "react";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";

// Components
import MayaText from "../typography/MayaText";
import MenuIcon from "./menuIcons";

type Props = {
  iconPath: any;
  onPress?: () => void;
  label?: string;
};

const MenuItem = ({ iconPath, onPress, label }: Props) => {
  return (
    <TouchableOpacity style={styles.menuItemWrapper} onPress={onPress}>
      <MenuIcon path={iconPath} />
      <MayaText
        style={{
          fontSize: 16,
          color: "#2F323F",
          lineHeight: 22,
          paddingLeft: 12,
        }}
        numberOfLines={1}
      >
        {label}
      </MayaText>
    </TouchableOpacity>
  );
};

export default MenuItem;

const styles = StyleSheet.create({
  menuItemWrapper: {
    flexDirection: "row",
    // alignItems: "center",
    marginBottom: 40,
    // right: "10%",
  },
});
