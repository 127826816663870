import { createSlice } from "@reduxjs/toolkit";
import { UserModel } from "../models/user.model";
import {
  changePassAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";

export type UserState = {
  user: UserModel | null;
  loading: boolean;
  error: string | undefined;
};

const initialState: UserState = {
  user: null,
  loading: false,
  error: undefined,
};

const userSlice = createSlice({
  name: "user_state",
  initialState,
  reducers: {
    setUserProfile: (state, { payload }) => {
      state.user = payload;
    },
    setKeyFromProfile: (state, { payload }) => {
      //@ts-ignore
      state.user = {
        ...state.user,
        [payload.key]: payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserProfileAsyncThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateUserProfileAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;

        alert("Profile updated successfully!");
      }
    );
    builder.addCase(updateUserProfileAsyncThunk.rejected, (state) => {
      state.loading = false;
      state.error = undefined;
      state.user = null;
      alert("Profile update failed. Please try again later.");
    });

    builder.addCase(changePassAsyncThunk.pending, (state, action) => {});
    builder.addCase(changePassAsyncThunk.fulfilled, (state, action) => {
      alert("Your password has been successfully changed");
      setTimeout(() => {
        action.meta.arg.nav.goBack();
      });
    });
    builder.addCase(changePassAsyncThunk.rejected, (state, action) => {
      alert("The entered password information is incorrect");
    });
  },
});

export default userSlice.reducer;
export const { setUserProfile, setKeyFromProfile } = userSlice.actions;
