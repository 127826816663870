import React, { useRef, useEffect } from "react";
import { View, Animated, StyleSheet, Easing } from "react-native";
import { GradientBox } from "../typography/GradientBox";
export const Loading = () => {
  const animatedValues = [
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
  ];

  useEffect(() => {
    const animations = animatedValues.map((animatedValue, index) => {
      return Animated.loop(
        Animated.sequence([
          Animated.timing(animatedValue, {
            toValue: 1,
            duration: 500,
            easing: Easing.inOut(Easing.ease),
            delay: index * 200,
            useNativeDriver: true,
          }),
          Animated.timing(animatedValue, {
            toValue: 0,
            duration: 500,
            easing: Easing.inOut(Easing.ease),
            useNativeDriver: true,
          }),
        ])
      );
    });

    Animated.stagger(200, animations).start();
  }, []);

  return (
    <View style={styles.container}>
      {animatedValues.map((animatedValue, index) => {
        const scale = animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [1, 1.5],
        });
        const opacity = animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [0.3, 1],
        });

        return (
          <GradientBox key={index}>
            <View
              style={{
                height: 20,

                display: "flex",
                justifyContent: "center",
              }}
            >
              <Animated.View
                style={[
                  styles.dot,
                  { transform: [{ scale }], opacity: opacity },
                ]}
              />
            </View>
          </GradientBox>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 10,
    backgroundColor: "blue",
    marginHorizontal: 5,
  },
});
