import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import { colors } from "../../helpers/colors";
import { DATA_FOR_TRAINING } from "../../helpers/constants";
import { TOptionsWorkFlow } from "../../models/workFlow.model";
import { dispatch } from "../../store/store";
import { setWorkFlow } from "../../reducers/chatSlice";
import ChipWorkFlow from "../Chip/ChipWorkFlow";
import { isWireScreen } from "../../helpers/utils";

export type AutoTemplateActionSheetProps = {
  handleTemplate: (template: string) => void;
  stylesProps?: any;
};

const AutoTemplateActionSheet = ({
  handleTemplate,
  stylesProps,
}: AutoTemplateActionSheetProps) => {
  const handlerChip = (option: TOptionsWorkFlow) => {
    dispatch(setWorkFlow(option));
    handleTemplate(option.name);
  };

  return (
    <View style={[styles.container, stylesProps]}>
      <ScrollView
        horizontal={true}
        style={{
          width: "100%",
          height: 100,
        }}
        contentContainerStyle={[
          isWireScreen() && {
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            // paddingHorizontal: 100,
          },
        ]}
      >
        <View style={[styles.chipContainer]}>
          {DATA_FOR_TRAINING.map((data, index) => (
            <ChipWorkFlow
              isFirstOption={index === 0}
              option={data}
              handleChipSelect={(option) => handlerChip(option)}
              key={data.id}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    width: "100%",

    padding: 10,
  },
  // text: {
  //   color: "white",
  //   fontSize: 18,
  //   padding: 2,
  // },
  chipContainer: {
    zIndex: 1000000,
    width: 600,
    flex: 1,

    flexDirection: "row",
    justifyContent: isWireScreen() ? "center" : "flex-start",
    flexWrap: "wrap",
    height: "auto",
    // alignSelf: "center",
  },
  // chipWrapper: {
  //   borderRadius: 38,
  //   borderColor: "rgba(255, 255, 255, 0.20)",
  //   borderWidth: 1,
  //   paddingVertical: 8,
  //   paddingHorizontal: 10,
  //   backgroundColor: colors.mineshaft,
  //   margin: 10,
  // },
});
export default AutoTemplateActionSheet;
