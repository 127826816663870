import {
  EXECUTABLE_TYPE,
   //ExeFactory,
} from "./execution-factory.service";
import { IExecutable } from "./executable.interface";

export class ShowResultsExec extends IExecutable {
  response_message: string;
  answers: any;
  node_id: string;

  constructor(response_message: string, answers: any) {
    super();
    this.response_message = response_message;
    this.answers = answers;
    this.node_id = EXECUTABLE_TYPE.MESSAGE;
  }

  execute() {
    // console.log("ShowResultsExec", `${this.response_message}`);
    return true;
  }
}
