import * as React from "react";

import { SafeAreaView } from "react-native-safe-area-context";
// import { WebView } from "react-native-webview";
import {
  Pressable,
  StyleSheet,
  View,
  Image,
  PermissionsAndroid,
  Platform,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { RouteNames } from "../../navigation/routeNames";
import { URLS } from "../../helpers/constants";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import ButtonWide from "../general/ButtonWide";
// import RNFS from "react-native-fs";
// import Share from "react-native-share";

const getPermissionAndroid = async () => {
  // try {
  //   const granted = await PermissionsAndroid.request(
  //     PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
  //     {
  //       title: "Storage Permission Required",
  //       message: "App needs access to your storage to download the file",
  //       buttonNeutral: "Ask Me Later",
  //       buttonNegative: "Cancel",
  //       buttonPositive: "OK",
  //     }
  //   );
  //   return granted === PermissionsAndroid.RESULTS.GRANTED;
  // } catch (err) {
  //   console.warn(err);
  //   return false;
  // }
};

const WebViewComponent = ({ url }) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const webviewRef = React.useRef<any>();

  function onMessage(data: any) {
    console.log("onMessage", data.nativeEvent.data);
    // navigation.navigate(RouteNames.Login);
  }

  const page = url ? url.split("page=")[1] : null;

  const customCSS = `
    body {
      font-size: 16px;
      color: #333;
      font-family: Arial, sans-serif;
    }
    h1 {
      color: #FF5733;
    }
  `;

  const jsCode = page
    ? `
    setTimeout(function() {
      window.scrollTo(0, window.scrollY + ${page} * window.innerWidth * 0.805);
    }, 1000); // Scroll to page 25 after 1 second
  `
    : `
      const styleElement = document.createElement('style');
      styleElement.innerHTML = \`${customCSS}\`;
      document.head.appendChild(styleElement);
    `;

  const downloadFile = async () => {
    if (Platform.OS === "android") {
      const granted = await getPermissionAndroid();
      if (!granted) {
        Alert.alert(
          "Permission Denied!",
          "You need to give storage permission to download the file"
        );
        return;
      }
    }
    const fileName = url.split("/").pop(); // Lấy tên file từ URL
    const filePath = `${RNFS.DocumentDirectoryPath}/${fileName}`;
    RNFS.downloadFile({
      fromUrl: url,
      toFile: filePath,
    })
      .promise.then((result) => {
        if (result.statusCode === 200) {
          Alert.alert("Download Successful!");
        } else {
          Alert.alert("Download Failed", `Status Code: ${result.statusCode}`);
        }
      })
      .catch((error) => {
        console.log(error);
        Alert.alert("Download Failed", `Error: ${error.message}`);
      });
  };

  const sharePDF = async () => {
    const shareOptions = {
      title: "Share PDF",
      message: "Let check this PDF!",
      url: url,
      type: "application/pdf",
    };
    try {
      const result = await Share.open(shareOptions);
      console.log(result);
    } catch (error) {
      if (error.message !== "User did not share") {
        Alert.alert("Error", "Can't share this PDF");
        console.error(error);
      }
    }
  };

  return (
    <>
      <View style={{ flex: 1 }}>
        {/* 
        <WebView
          ref={webviewRef}
          originWhitelist={["*"]}
          scalesPageToFit={true}
          mixedContentMode="compatibility"
          onMessage={onMessage}
          source={{
            uri: url,
          }}
          injectedJavaScript={jsCode}
        />
        */}
      </View>

      <Pressable
        onPress={() => {
          if (navigation.canGoBack()) navigation.goBack();
          else {
            navigation.navigate(RouteNames.Chat);
          }
        }}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          // height: 24,
          // width: 24,
          backgroundColor: "transparent",
          padding: 16,
          paddingTop: 0,
        }}
      >
        <Image
          style={{ width: 36, height: 36 }}
          source={require("../../assets/newDesign/close-circle-bold.png")}
        />
      </Pressable>

      <View
        style={{
          position: "absolute",
          bottom: 40,
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <ButtonWide
          style={{
            width: "36%",

            height: 44,
            backgroundColor: "#F6F6F6",
          }}
          styleText={{
            fontSize: 14,
            color: "#2F323F",
          }}
          onPress={sharePDF}
          text="Share"
        />
        <ButtonWide
          style={{
            width: "36%",
            height: 44,
            backgroundColor: "#F6F6F6",
          }}
          styleText={{
            fontSize: 14,
            color: "#2F323F",
          }}
          onPress={downloadFile}
          text="Download"
        />
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
});

export default WebViewComponent;
