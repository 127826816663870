import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const FileIcon = (props) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M22 10.5V15.5C22 20.5 20 22.5 15 22.5H9C4 22.5 2 20.5 2 15.5V9.5C2 4.5 4 2.5 9 2.5H14"
      fill={props.fill}
    />
    <Path
      d="M22 10.5V15.5C22 20.5 20 22.5 15 22.5H9C4 22.5 2 20.5 2 15.5V9.5C2 4.5 4 2.5 9 2.5H14"
      stroke={props.fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M12.5 16.5H6"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M6 12.5L9.5 12.5"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M22 10.5H18C15 10.5 14 9.5 14 6.5V2.5"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </Svg>
);
