// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useRef, useState } from "react";
import {
  Button,
  Dimensions,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Helpers

import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import { Image } from "react-native";
import { Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { RouteNames } from "../navigation/routeNames";
import { WrapperView } from "../components/Layout/WrapperView";
import { isWireScreen } from "../helpers/utils";
import ProfileHOC from "./ProfileHOC";
import PasscodeHOC from "./PasscodeHOC";
import { ScrollView } from "react-native-web-hover";
import { GradientBox } from "../components/typography/GradientBox";

const SettingsMenuHoc: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedValue, setselectedValue] = useState(null);
  const [textValue, setTextValue] = useState("");
  const navigation = useNavigation();

  const [state, setState] = useState({
    selectedTab: "user_profile",
  });

  const closeModal = () => {
    setSelectedTheme("");
  };

  const handleNavigation = () => {
    console.log("goBack");

    navigation.goBack();
  };

  const scrollViewRef = useRef();

  if (isWireScreen()) {
    return (
      <WrapperView>
        <View
          style={{
            padding: 24,
            flexDirection: "row",
            backgroundColor: "white",
          }}
        >
          <View style={{ width: 210 }}>
            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
                setState({ ...state, selectedTab: "user_profile" });
              }}
            >
              <Text
                style={[
                  styles.tabItem,
                  state.selectedTab === "user_profile" && styles.tabItemActive,
                ]}
              >
                User profile
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                scrollViewRef.current.scrollTo({
                  x: 0,
                  y: 600,
                  animated: true,
                });

                setState({ ...state, selectedTab: "passcode" });
              }}
            >
              <Text
                style={[
                  styles.tabItem,
                  state.selectedTab === "passcode" && styles.tabItemActive,
                ]}
              >
                Passcode
              </Text>
            </TouchableOpacity>
          </View>
          <ScrollView
            style={{
              flex: 1,
              height:
                Dimensions.get("screen").height -
                (Platform.OS === "web" ? 400 : 220),
            }}
            ref={scrollViewRef}
          >
            <View
              style={{
                flexDirection: "row",
                marginBottom: 40,
                paddingLeft: 16,
              }}
            >
              <GradientBox>
                <View
                  style={{
                    width: 20,
                    height: 20,
                    borderWidth: 1,
                    backgroundColor: "blue",
                    borderRadius: 10,
                  }}
                ></View>
              </GradientBox>

              <Text
                style={{
                  marginLeft: 8,
                  fontSize: 18,
                  fontWeight: "700",
                  color: "#131418",
                }}
              >
                Personal information
              </Text>
            </View>
            <View style={{ marginBottom: 40 }}>
              <ProfileHOC />
            </View>
            <View
              style={{
                flexDirection: "row",
                marginBottom: 40,
                paddingLeft: 16,
              }}
            >
              <GradientBox>
                <View
                  style={{
                    width: 20,
                    height: 20,
                    borderWidth: 1,
                    backgroundColor: "blue",
                    borderRadius: 10,
                  }}
                ></View>
              </GradientBox>

              <Text
                style={{
                  marginLeft: 8,
                  fontSize: 18,
                  fontWeight: "700",
                  color: "#131418",
                }}
              >
                Account
              </Text>
            </View>
            <PasscodeHOC />
          </ScrollView>
        </View>
      </WrapperView>
    );
  }

  return (
    <WrapperView>
      <View style={styles.container}>
        <Pressable
          onPress={() => {
            navigation.navigate(RouteNames.Profile);
          }}
          style={styles.itemWrapper}
        >
          <Image
            style={styles.itemIcon}
            source={require("../assets/newDesign/setting-icons/frame.png")}
          />
          <Text style={styles.itemText}>User profile</Text>

          <Image
            style={{ width: 20, height: 20 }}
            source={require("../assets/newDesign/arrow-right.png")}
          />
        </Pressable>
        <Pressable
          onPress={() => {
            navigation.navigate(RouteNames.PasscodeEdit);
          }}
          style={styles.itemWrapper}
        >
          <Image
            style={styles.itemIcon}
            source={require("../assets/newDesign/setting-icons/key.png")}
          />
          <Text style={styles.itemText}>Passcode</Text>

          <Image
            style={{ width: 20, height: 20 }}
            source={require("../assets/newDesign/arrow-right.png")}
          />
        </Pressable>
        {/* <Pressable
        onPress={() => {
          navigation.navigate(RouteNames.GeneralSettings);
        }}
        style={styles.itemWrapper}
      >
        <Image
          style={styles.itemIcon}
          source={require("../assets/newDesign/setting-icons/category.png")}
        />
        <Text style={styles.itemText}>General Settings</Text>

        <Image style={{width: 20, height: 20}} source={require("../assets/newDesign/arrow-right.png")} />
      </Pressable>

      <Pressable
        onPress={() => {
          navigation.navigate(RouteNames.BusinessInfo);
        }}
        style={styles.itemWrapper}
      >
        <Image
          style={styles.itemIcon}
          source={require("../assets/newDesign/setting-icons/profile-2user.png")}
        />
        <Text style={styles.itemText}>Business information</Text>

        <Image style={{width: 20, height: 20}} source={require("../assets/newDesign/arrow-right.png")} />
      </Pressable>

      <Pressable
        onPress={() => {
          navigation.navigate(RouteNames.Notifications);
        }}
        style={styles.itemWrapper}
      >
        <Image
          style={styles.itemIcon}
          source={require("../assets/newDesign/setting-icons/notification.png")}
        />
        <Text style={styles.itemText}>Notifications</Text>

        <Image style={{width: 20, height: 20}} source={require("../assets/newDesign/arrow-right.png")} />
      </Pressable>

      <Pressable
        onPress={() => {
          navigation.navigate(RouteNames.Payment);
        }}
        style={styles.itemWrapper}
      >
        <Image
          style={styles.itemIcon}
          source={require("../assets/newDesign/setting-icons/card-pos.png")}
        />
        <Text style={styles.itemText}>Payment</Text>

        <Image style={{width: 20, height: 20}} source={require("../assets/newDesign/arrow-right.png")} />
      </Pressable> */}
      </View>
    </WrapperView>
  );
};

export default SettingsMenuHoc;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // backgroundColor: "#F3F3F3",
    // padding: 20,
    // opacity: 0.3,
    paddingHorizontal: 20,
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
  },
  itemWrapper: {
    backgroundColor: "white",
    borderRadius: 8,
    paddingHorizontal: 20,
    height: 56,
    marginBottom: 16,
    flexDirection: "row",
    paddingVertical: 20,
    alignItems: "center",
  },
  itemIcon: { width: 24, height: 24 },
  itemText: {
    fontSize: 16,
    color: "#2F323F",
    fontWeight: "400",
    //   lineHeight: 26,
    marginLeft: 16,
    flex: 1,

    //   borderWidth: 1,
    height: 28,
    lineHeight: 26,
  },

  tabItem: {
    marginBottom: 24,
    fontSize: 16,
    fontWeight: "400",
    color: "#2F323F",
    borderRadius: 8,
    overflow: "hidden",
    paddingHorizontal: 24,
    paddingVertical: 12,
    backgroundColor: "white",

    // textAlign: "center",
  },
  tabItemActive: {
    fontWeight: "600",
    color: "#131418",
    backgroundColor: "#F3F3F3",
  },
});
