import React, { useEffect } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  View,
  Text,
  Pressable,
} from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";
import ButtonWide from "../components/general/ButtonWide";

const ToggleInput = ({
  checked = false,
  onChange = (newValue: boolean) => {},
  style = {},
}) => {
  return (
    <Pressable
      onPress={() => {
        onChange(!checked);
      }}
      style={style}
    >
      {checked ? (
        <Image source={require("../assets/newDesign/toggle-input/on.png")} />
      ) : (
        <Image source={require("../assets/newDesign/toggle-input/off.png")} />
      )}
    </Pressable>
  );
};

const NotificationsHOC = () => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
  };

  const pressSave = () => {
    dispatch(updateUserProfileAsyncThunk(profile as UserModel));
  };

  useEffect(() => {
    if (!profile || !profile.email) {
      dispatch(getUserAsyncThunk());
    }
  }, []);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.rowTitle}>
          Product updates and community announcements
        </Text>
        <Image
          style={styles.rowInfoIcon}
          source={require("../assets/newDesign/info-icon.png")}
        />
        <ToggleInput style={styles.toggleInput} checked={true} />
      </View>
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Comments</Text>
        <Image
          style={styles.rowInfoIcon}
          source={require("../assets/newDesign/info-icon.png")}
        />
        <ToggleInput style={styles.toggleInput} />
      </View>
      <View style={styles.row}>
        <Text style={styles.rowTitle}>Purchases</Text>
        <Image
          style={styles.rowInfoIcon}
          source={require("../assets/newDesign/info-icon.png")}
        />
        <ToggleInput style={styles.toggleInput} />
      </View>

      <ButtonWide
        text={"Save changes"}
        onPress={() => {}}
        // loading={loadingUrl}
        // disabled={loadingUrl || !newValue}
      />
    </ScrollView>
  );
};

export default NotificationsHOC;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
    paddingHorizontal: 20,
  },
  row: {
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  rowTitle: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 28,
    color: "#2F323F",
    maxWidth: 210,
  },
  rowInfoIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
  },
  toggleInput: {
    flex: 1,
    flexDirection: "row-reverse",
  },
});
