import React, { useEffect, useState } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  changePassAsyncThunk,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";
import ButtonWide from "../components/general/ButtonWide";
import { WrapperView } from "../components/Layout/WrapperView";

const PasscodeHOC = () => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
  };

  const pressSave = () => {
    if (state.confirmNewPass !== state.newPass) {
      alert("New password and confirmation do not match");
      return;
    }

    const errorMessages = validatePassword(state.newPass);
    if (errorMessages.length > 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    dispatch(
      changePassAsyncThunk({
        oldPass: state.oldPass,
        newPass: state.newPass,
        nav: navigation,
      })
    );
  };

  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (!profile || !profile.email) {
      dispatch(getUserAsyncThunk());
    }
  }, []);

  const [state, setState] = useState({
    oldPass: "",
    newPass: "",
    confirmNewPass: "",
  });

  return (
    <ScrollView style={{ height: "100%", backgroundColor: "white" }}>
      <View style={styles.sectionContainer}>
        {/* <MayaText style={styles.title}>ACCOUNT INFORMATION</MayaText> */}
        <ProfileField
          isPassword={true}
          label={"Old password"}
          value={state.oldPass}
          attr="oldPass"
          onPress={(_, newValue) => setState({ ...state, oldPass: newValue })}
          enableArrow={false}
        />

        <ProfileField
          isPassword={true}
          label={"New password"}
          value={state.newPass}
          attr="newPass"
          onPress={(_, newValue) => setState({ ...state, newPass: newValue })}
          enableArrow={false}
        />

        <ProfileField
          isPassword={true}
          label={"Confirm new password"}
          value={state.confirmNewPass}
          attr="confirmNewPass"
          onPress={(_, newValue) =>
            setState({ ...state, confirmNewPass: newValue })
          }
          enableArrow={false}
        />

        <ButtonWide text={"Save new password"} onPress={pressSave} />
      </View>
    </ScrollView>
  );
};

export default PasscodeHOC;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    padding: 20,
  },
  editButton: {
    alignSelf: "center",
    fontWeight: "600",
    color: colors.anakiwa,
  },
  sectionContainer: {
    // padding: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.background2,
    borderRadius: 30,
    width: "100%",
    alignSelf: "flex-start",
  },
  title: {
    fontWeight: "600",
    fontSize: 10,
    marginBottom: 12,
  },
});

export const validatePassword = (password) => {
  const message = [];
  const criteria = {
    minLength: 8,
    hasUpperCase: /[A-Z]/,
    hasLowerCase: /[a-z]/,
    hasNumber: /\d/,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/,
  };

  if (password.length < criteria.minLength) {
    message.push(
      `Password must be at least ${criteria.minLength} characters long.`
    );
  }

  if (!criteria.hasUpperCase.test(password)) {
    message.push("Password must contain at least one uppercase letter.");
  }

  if (!criteria.hasLowerCase.test(password)) {
    message.push("Password must contain at least one lowercase letter.");
  }

  if (!criteria.hasNumber.test(password)) {
    message.push("Password must contain at least one number.");
  }

  if (!criteria.hasSpecialChar.test(password)) {
    message.push("Password must contain at least one special character.");
  }

  return message;
};
