import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import { colors } from "../helpers/colors";
import UrlInputScreen from "../components/training/UrlInputString";
import MobileViewHOC from "./MobileViewHOC";

type Props = {
  onStartTraining: () => {};
};

const UrlHOC: React.FC = ({ onStartTraining }: Props) => {
  return <UrlInputScreen onStartTraining={onStartTraining} />;
};

export default UrlHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
});
