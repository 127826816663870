import "expo-dev-client";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  LogBox,
  Platform,
  StatusBar,
  StyleSheet,
  View,
} from "react-native";

import { Provider, ReactReduxContext } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";

import { colors } from "./src/helpers/colors";
import RootNavigation from "./src/navigation/RootNavigation";
import store, { persistor } from "./src/store/store";
import Toast from "react-native-toast-message";
import { toastConfig } from "./src/hooks/useNotification";
import { Audio } from "expo-av";

// import * as Sentry from "sentry-expo";

// LogBox.ignoreLogs(["new NativeEventEmitter"]);
// Sentry.init({
//   dsn: "https://0ddfad62d7be50b71e23cb4a6effb34f@o4506796325076992.ingest.sentry.io/4506796586958848",
//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   // We recommend adjusting this value in production.
//   tracesSampleRate: 1.0,
//   debug: false,
// });

function App() {
  return (
    <SafeAreaProvider>
      <SafeAreaView edges={["top"]} style={styles.container}>
        <View
          style={[
            {
              height: "100%",
              flex: 1,
            },
          ]}
        >
          <StatusBar barStyle={"dark-content"} backgroundColor="white" />

          <Provider store={store} context={ReactReduxContext}>
            <PersistGate loading={null} persistor={persistor}>
              <RootNavigation />
              <Toast
                topOffset={60}
                autoHide={true}
                visibilityTime={5000}
                config={toastConfig}
              />
            </PersistGate>
          </Provider>
        </View>
      </SafeAreaView>
    </SafeAreaProvider>
  );
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingTop: StatusBar.currentHeight ?? 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});
