import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types";
import React, { useEffect } from "react";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import { AppDispatch } from "../store/store";
import { updateReadStatusAsyncThunk } from "../api/notifications/notification";
import { colors } from "../helpers/colors";
import { ETrainingStatus } from "../models/trainingEvent.model";
import { Image, Platform } from "react-native";
import { isWireScreen } from "../helpers/utils";

export const toastConfig = {
  /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
  success: (props) => (
    <BaseToast
      duration={11100}
      text1NumberOfLines={4}
      {...props}
      style={[
        {
          // borderLeftColor: colors.springGreen,
          width: "100%",
          height: "auto",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,

          marginTop: 6,
        },
        isWireScreen() && {
          alignSelf: "flex-end",
          marginRight: 16,
          width: 300,
        },
      ]}
      contentContainerStyle={{ padding: 16 }}
      text1Style={{
        fontSize: 14,
        fontWeight: "400",
        color: "#131418",
        // padding: 5,
      }}
      renderLeadingIcon={() => (
        <Image
          style={{
            height: 24,
            width: 24,
            marginTop: 16,
            marginLeft: 16,
          }}
          source={require("../assets/newDesign/success-icon.png")}
        />
      )}
    />
  ),
  info: (props) => (
    <BaseToast
      text1NumberOfLines={4}
      {...props}
      style={[
        {
          // borderLeftColor: colors.springGreen,
          width: "96%",
          height: "auto",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,

          marginTop: 6,
        },
        isWireScreen() && {
          alignSelf: "flex-end",
          marginRight: 16,
          width: 300,
        },
      ]}
      contentContainerStyle={{ padding: 16 }}
      text1Style={{
        fontSize: 14,
        fontWeight: "400",
        color: "#131418",
        // verticalAlign: "middle",
        // padding: 5,
      }}
      renderLeadingIcon={() => (
        <Image
          style={{
            height: 24,
            width: 24,
            marginTop: 12,
            marginLeft: 16,
          }}
          source={require("../assets/newDesign/info-icon.png")}
        />
      )}
    />
  ),
  /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
  error: (props) => (
    <ErrorToast
      text1NumberOfLines={4}
      style={[
        {
          width: "100%",
          height: "auto",
          backgroundColor: "#fc5b5b",
          borderRadius: 8,

          marginTop: 6,
        },
        isWireScreen() && {
          alignSelf: "flex-end",
          marginRight: 16,
          width: 300,
        },
      ]}
      contentContainerStyle={{ padding: 16 }}
      {...props}
      text1Style={{
        tSize: 14,
        fontWeight: "400",
        color: "white",
      }}
      renderLeadingIcon={() => (
        <Image
          style={{
            height: 24,
            width: 24,
            marginTop: 16,
            marginLeft: 16,
          }}
          source={require("../assets/newDesign/success-icon.png")}
        />
      )}
    />
  ),
};

const useNotification = () => {
  const errorMessage =
    "The document you just uploaded does not have any text. We are unable to process it. This will be available in the next release.";
  const dispatch = useDispatch<AppDispatch>();
  const { userId } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const { display, trainingEvent } = useSelector(
    (state: RootState) => state.root.notificationReducer
  );

  const trainingEventItem = (trainingEvent: any[]) => {
    return trainingEvent.map((item) => {
      const successMessage =
        item.status === ETrainingStatus.SUCCESS &&
        item.message.includes("Removing")
          ? "The document has been successfully deleted"
          : "Maya successfully processed";

      const message =
        item.status === ETrainingStatus.SUCCESS
          ? successMessage
          : item.status === ETrainingStatus.PROCESSING
            ? item.message
            : errorMessage;
      console.debug("trainingEventItem", item.status);
      Toast.show({
        autoHide: false,
        type:
          item.status === ETrainingStatus.SUCCESS
            ? "success"
            : ETrainingStatus.PROCESSING
              ? "info"
              : "error",
        text1: String(item.message)
          .replaceAll(/[\[\]]/g, "")
          .replaceAll(/for <[^>]+>/g, ""),
        onPress: () => {
          Toast.hide();
          if (item.request_id)
            dispatch(
              updateReadStatusAsyncThunk({
                requestId: item.request_id,
                userId,
              })
            );
        },
        onHide: () => {
          if (item.request_id)
            dispatch(
              updateReadStatusAsyncThunk({
                requestId: item.request_id,
                userId,
              })
            );
        },
      });
    });
  };

  useEffect(() => {
    // console.log("useNotification", trainingEvent);
    if (trainingEvent && trainingEvent.length > 0) {
      trainingEventItem(trainingEvent);
    }
  }, [trainingEvent]);

  return trainingEvent;
};

export default useNotification;
