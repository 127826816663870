import React, { useState } from "react";
import {
  Image,
  Keyboard,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";

// Components
import MayaText from "../typography/MayaText";

// Icons
import EyeIcon from "../../icons/eye";

// Constants
import { colors } from "../../helpers/colors";

type Props = {
  value: string;
  setValue: (email: string) => void;
  placeholder: string;
  isPassword?: boolean;
  style?: StyleProp<ViewStyle>;
  showError?: boolean;
  errorMessage?: string;
  secureTextEntry?: boolean;
};

const CustomTextInput = ({
  value,
  setValue,
  placeholder,
  isPassword,
  style,
  showError,
  errorMessage,
  secureTextEntry,
}: Props) => {
  const borderStyle = showError ? { borderColor: "red" } : {};
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(secureTextEntry);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== "web") Keyboard.dismiss();
      }}
    >
      <View style={[styles.textinputWrapper, style, focused && borderStyle]}>
        <View style={styles.flexRowCenter}>
          <TextInput
            secureTextEntry={showPassword}
            placeholder={placeholder}
            placeholderTextColor={"#828BA3"}
            value={value}
            onChangeText={setValue}
            style={styles.textInput}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoCapitalize="none"
          />

          {isPassword && (
            <Pressable onPress={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/newDesign/eye-slash-bold.png")}
                ></Image>
              ) : (
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/newDesign/eye-slash-bold.png")}
                ></Image>
              )}
            </Pressable>
          )}
        </View>

        {showError && focused && (
          <MayaText style={styles.errorMessage}>{errorMessage}</MayaText>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default CustomTextInput;

const styles = StyleSheet.create({
  textinputWrapper: {
    // alignSelf: "center",

    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 10,
    borderColor: "#C7C8D9",
    borderWidth: 1,
    marginBottom: 16,

    // width: "120%",
  },
  errorMessage: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
  },
  textInput: {
    color: "black",
    flex: 1,
    outlineWidth: 0,
  },
  flexRowCenter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
