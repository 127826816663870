import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import { NavigationContainer, useRoute } from "@react-navigation/native";
import { Hoverable } from "react-native-web-hover";

// Types
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ParamListBase } from "@react-navigation/routers";

// Components
import MenuModal from "../components/general/menuModal";

// Icons
import BackIcon from "../icons/arrowLeft";
import { useDispatch, useSelector } from "react-redux";
import {
  setMenuModalVisible,
  setMenuModalVisible2,
} from "../reducers/generalSlice";
import { RootState } from "../../types";
import MenuContent, { handleQuit } from "../components/general/MenuContent";
import { AppDispatch } from "../store/store";
import { setMobile } from "../reducers/dimentionSlice";
import { RouteNames } from "./routeNames";
import { isWireScreen } from "../helpers/utils";
import { getUserAsyncThunk } from "../api/user/user";

export enum HeaderStyles {
  simple = "simple",
  simple_2 = "simple_2",
  simple_3 = "simple_3",
}

type Props = {
  title?: string;
  back?: boolean;
  navigation?: NativeStackNavigationProp<ParamListBase>;
  showMenu?: boolean;
  headerRight?: React.ReactNode;
  width?: number;
  headerStyle?: HeaderStyles;
  style?: any;
};

const OptionsNavigationHeader: React.FC<Props> = ({
  title,
  back,
  navigation,
  headerRight,
  headerStyle = HeaderStyles.simple,
  style = {},
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { menuModalVisible } = useSelector(
    (state: RootState) => state.root.generalReducer
  );

  const [state, setState] = useState({
    showTopMenu: false,
  });

  const haveNotify = false;

  const closeModal = () => {
    dispatch(setMenuModalVisible(false));
  };

  const [isMobile, setIsMobile] = useState(true);

  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  useEffect(() => {
    if (!profile || !profile.email) {
      dispatch(getUserAsyncThunk());
    }
  }, []);

  useEffect(() => {
    const handleScreenDimensionsChange = () => {
      const newWidth = Dimensions.get("window").width;
      setIsMobile(newWidth < 966); // Check if the device is mobile based on width
      dispatch(setMobile(newWidth < 966)); // Update Redux state
    };

    handleScreenDimensionsChange(); // Initial call

    const dimensionsListener = Dimensions.addEventListener(
      "change",
      handleScreenDimensionsChange
    );

    return () => {
      dimensionsListener.remove(); // Cleanup listener
    };
  }, []);

  const route = useRoute();
  const currentRoute = route.name;

  useEffect(() => {
    const handle = () => {
      setState({ ...state, showTopMenu: false });
    };
    navigation.addListener("blur", handle);

    return () => {
      navigation.removeListener("blur", handle);
    };
  }, []);

  if (isWireScreen()) {
    return (
      <View
        style={[
          {
            padding: 16,
            flexDirection: "row",
            // borderWidth: 1,
            borderBottomWidth: 1,
            borderColor: "#F2F2F2",
            backgroundColor: "white",
          },
          // style,
        ]}
      >
        <View style={{ flex: 1 }}>
          <Pressable
            onPress={() => {
              navigation.navigate(RouteNames.Chat);
            }}
          >
            <Image
              style={{ width: 130, height: 28 }}
              source={require("../assets/newDesign/Logo.png")}
            />
          </Pressable>
        </View>
        {/* <Pressable onPress={() => dispatch(setMenuModalVisible(true))}>
          {haveNotify ? (
            <Image
              source={require("../assets/newDesign/notification_have_notify.png")}
              style={{ width: 24, height: 24 }}
            />
          ) : (
            <Image
              source={require("../assets/newDesign/notification_empty.png")}
              style={{ width: 24, height: 24 }}
            />
          )}
        </Pressable> */}
        <Pressable
          style={{ marginLeft: 12 }}
          onPress={() =>
            setState({ ...state, showTopMenu: !state.showTopMenu })
          }
        >
          <Image
            source={require("../assets/newDesign/UserAvatar.png")}
            style={{ width: 32, height: 32 }}
          />
        </Pressable>
        {state.showTopMenu && (
          <View
            style={[
              {
                width: 227,
                position: "absolute",
                right: 10,
                top: 52,
                borderRadius: 8,
              },
              styles.boxShadow1,
            ]}
          >
            <View
              style={[
                styles.topDropdownItem,
                {
                  flexDirection: "row",
                },
              ]}
            >
              <Image
                source={require("../assets/newDesign/UserAvatar.png")}
                style={{ width: 40, height: 40 }}
              />
              <View style={{ marginLeft: 16 }}>
                <Text
                  style={{ color: "#131418", fontSize: 16, fontWeight: "700" }}
                >
                  {profile.firstname || "User Name"}
                </Text>
                <Text
                  style={{ fontSize: 12, fontWeight: "400", color: "#5F626E" }}
                >
                  {profile.position || "Member"}
                </Text>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => {
                navigation?.navigate(RouteNames.SettingsMenuHoc);
              }}
            >
              <Hoverable>
                {({ hovered }) => {
                  return (
                    <Text
                      style={[
                        styles.topDropdownItem,
                        hovered && styles.topDropdownItemActive,
                      ]}
                    >
                      User profile
                    </Text>
                  );
                }}
              </Hoverable>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigation?.navigate(RouteNames.SettingsMenuHoc);
              }}
            >
              <Hoverable>
                {({ hovered }) => {
                  return (
                    <Text
                      style={[
                        styles.topDropdownItem,
                        hovered && styles.topDropdownItemActive,
                      ]}
                    >
                      Setting
                    </Text>
                  );
                }}
              </Hoverable>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                handleQuit(dispatch, () => {
                  navigation?.navigate(RouteNames.Login);
                });
              }}
            >
              <Hoverable>
                {({ hovered }) => {
                  return (
                    <Text
                      style={[
                        styles.topDropdownItem,
                        hovered && styles.topDropdownItemActive,
                      ]}
                    >
                      Logout
                    </Text>
                  );
                }}
              </Hoverable>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }

  if (headerStyle === HeaderStyles.simple)
    return (
      <View style={styles.container}>
        {back && (
          <Pressable
            style={{ paddingHorizontal: 15 }}
            onPress={() => navigation?.goBack()}
          >
            <BackIcon />
          </Pressable>
        )}

        <Pressable onPress={() => {}}>
          <View
            style={[
              Platform.OS == "web" && { marginLeft: 10 },
              { width: "100%", justifyContent: "center", alignItems: "center" },
            ]}
          >
            <View
              style={{
                flex: 1,
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Image
              style={{
                width: Platform.OS == "web" ? "100%" : 130, // Adjust width as needed
                height: Platform.OS == "web" ? "100%" : 80, // Adjust height as needed
                resizeMode: "contain", // Ensure image fits within dimensions without stretching
              }}
              source={require("../assets/newDesign/Logo.png")}
            /> */}
              {title ? (
                <Text style={{ height: 20, fontSize: 15, fontWeight: "600" }}>
                  {title}
                </Text>
              ) : (
                <></>
              )}
            </View>
          </View>
        </Pressable>

        {headerRight ? (
          <Pressable onPress={() => dispatch(setMenuModalVisible(true))}>
            {isMobile && (
              <Image
                source={require("../assets/newDesign/burgerMenu.png")}
                style={{ width: 35, height: 35 }}
              />
            )}
          </Pressable>
        ) : (
          <View style={{ width: 30 }} />
        )}

        <MenuModal isVisible={menuModalVisible} closeModal={closeModal}>
          <MenuContent closeModal={closeModal} />
        </MenuModal>
      </View>
    );

  if (headerStyle === HeaderStyles.simple_2)
    return (
      <View
        style={[
          {
            paddingHorizontal: 20,
            paddingTop: 24,
            paddingBottom: 24,
          },
          style,
        ]}
      >
        {back && (
          <Pressable style={[]} onPress={() => navigation?.goBack()}>
            <Image
              style={{ width: 32, height: 32 }}
              source={require("../assets/newDesign/arrow-left.png")}
            />
          </Pressable>
        )}

        <Pressable onPress={() => {}} style={{ marginTop: 16 }}>
          <View style={{}}>
            {/* <Image
            style={{
              width: Platform.OS == "web" ? "100%" : 130, // Adjust width as needed
              height: Platform.OS == "web" ? "100%" : 80, // Adjust height as needed
              resizeMode: "contain", // Ensure image fits within dimensions without stretching
            }}
            source={require("../assets/newDesign/Logo.png")}
          /> */}
            {title ? (
              <Text style={{ fontSize: 24, fontWeight: "700", lineHeight: 32 }}>
                {title}
              </Text>
            ) : (
              <></>
            )}
          </View>
        </Pressable>

        {headerRight ? (
          <Pressable onPress={() => dispatch(setMenuModalVisible(true))}>
            {isMobile && (
              <Image
                source={require("../assets/newDesign/burgerMenu.png")}
                style={{ width: 35, height: 35 }}
              />
            )}
          </Pressable>
        ) : (
          <View style={{ width: 30 }} />
        )}

        <MenuModal isVisible={menuModalVisible} closeModal={closeModal}>
          <MenuContent closeModal={closeModal} />
        </MenuModal>
      </View>
    );

  if (headerStyle == HeaderStyles.simple_3) {
    return (
      <View
        style={[
          {
            padding: 16,
            flexDirection: "row",
            // borderWidth: 1,
            borderBottomWidth: 1,
            borderColor: "#F2F2F2",
            backgroundColor: "white",
          },
          style,
        ]}
      >
        <View style={{ flex: 1 }}>
          <Pressable
            onPress={() => {
              navigation.navigate(RouteNames.Chat);
            }}
          >
            <Image
              style={{ width: 130, height: 28 }}
              source={require("../assets/newDesign/Logo.png")}
            />
          </Pressable>
        </View>
        {/* <Pressable onPress={() => dispatch(setMenuModalVisible(true))}>
          {haveNotify ? (
            <Image
              source={require("../assets/newDesign/notification_have_notify.png")}
              style={{ width: 24, height: 24 }}
            />
          ) : (
            <Image
              source={require("../assets/newDesign/notification_empty.png")}
              style={{ width: 24, height: 24 }}
            />
          )}
        </Pressable> */}
        <Pressable
          style={{ marginLeft: 12 }}
          onPress={() => dispatch(setMenuModalVisible(true))}
        >
          <Image
            source={require("../assets/newDesign/burgerMenu.png")}
            style={{ width: 24, height: 24 }}
          />
        </Pressable>
        <MenuModal isVisible={menuModalVisible} closeModal={closeModal}>
          <MenuContent closeModal={closeModal} />
        </MenuModal>
      </View>
    );
  }

  return null;
};

export default OptionsNavigationHeader;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    borderBottomColor: "#F2F2F2",
    borderBottomWidth: 1,
  },
  topDropdownItem: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    fontSize: 16,
    fontWeight: "400",
  },
  topDropdownItemActive: {
    backgroundColor: "#F3F3F3",
    fontWeight: "600",
    color: "#131418",
  },

  boxShadow1: {
    // width: 100,
    // height: 100,
    backgroundColor: "white",
    // Đổ bóng cho iOS
    shadowColor: "#606170",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.12, // Điều chỉnh để phù hợp với mức độ mờ mong muốn
    shadowRadius: 12,
    // Đổ bóng cho Android
    elevation: 4,
  },
});
