import React from "react";
import { Image, StyleSheet, View } from "react-native";

// Constants
import { colors } from "../../helpers/colors";

type Props = { path: any };

const MenuIcon = ({ path }: Props) => {
  return (
    <Image
      source={path}
      style={{ width: 24, height: 24 }}
      resizeMode="contain"
    />
  );
};

export default MenuIcon;
