import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import { TOptionsWorkFlow } from "../../models/workFlow.model";
import { colors } from "../../helpers/colors";

export const ChipWorkFlow = ({
  option,
  handleChipSelect,
  isFirstOption,
}: {
  option: TOptionsWorkFlow;
  handleChipSelect: (option: TOptionsWorkFlow) => void;
  isFirstOption: boolean;
}) => {
  return (
    <TouchableOpacity onPress={() => handleChipSelect(option)}>
      <View
        style={{
          ...styles.chipWrapper,
          ...(isFirstOption ? styles.chipWrapperFirst : {}),
        }}
      >
        <Text
          style={{ ...styles.text, ...(isFirstOption ? styles.textFirst : {}) }}
        >
          {option.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  text: {
    // color: "white",
    // fontSize: Platform.OS == "web" ? 16 : 18,
    // padding: 2,
    color: colors.black,
    fontSize: Platform.OS == "web" ? 16 : 12,
    lineHeight: 20,
  },
  textFirst: {
    color: "white",
  },
  // chipContainer: {
  //   flexDirection: "row",
  //   justifyContent: "flex-start",
  //   flexWrap: "nowrap",
  //   height: "auto",
  // },
  chipWrapper: {
    // borderRadius: 38,
    // borderColor: "rgba(255, 255, 255, 0.20)",
    // borderWidth: 1,
    // paddingVertical: 8,
    // paddingHorizontal: Platform.OS == "web" ? 6 : 10,
    // backgroundColor: colors.mineshaft,
    // margin: 10,

    borderRadius: 30,
    // borderColor: "black",
    // borderWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 17,
    backgroundColor: colors.secondaryGray,
    margin: 10,
    height: 30,
  },

  chipWrapperFirst: {
    backgroundColor: colors.primaryBlue,
  },
});

export default ChipWorkFlow;
