import React, { useEffect } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  View,
  Text,
  TextInput,
} from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";
import ButtonWide from "../components/general/ButtonWide";
import { GradientButton } from "../components/typography/GradientButton";

const PaymentHOC = () => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
  };

  const pressSave = () => {
    dispatch(updateUserProfileAsyncThunk(profile as UserModel));
  };

  useEffect(() => {
    if (!profile || !profile.email) {
      dispatch(getUserAsyncThunk());
    }
  }, []);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.row}>
        <Image source={require("../assets/newDesign/Paypal.png")} />
        <GradientButton>Update</GradientButton>
      </View>

      <View>
        <Text style={{ marginBottom: 24, fontSize: 16, fontWeight: "600" }}>
          Add a new card
        </Text>

        <TextInput
          defaultValue={""}
          onChangeText={(newValue) => {
            // onPress(attr, newValue);
          }}
          placeholder="Full Name"
          style={styles.input}
        />
        <TextInput
          defaultValue={""}
          onChangeText={(newValue) => {
            // onPress(attr, newValue);
          }}
          placeholder="16 - Digit Number"
          style={styles.input}
        />
        <TextInput
          defaultValue={""}
          onChangeText={(newValue) => {
            // onPress(attr, newValue);
          }}
          placeholder="Exp. Date"
          style={styles.input}
        />
        <TextInput
          defaultValue={""}
          onChangeText={(newValue) => {
            // onPress(attr, newValue);
          }}
          placeholder="CVV"
          style={styles.input}
        />
        <TextInput
          defaultValue={""}
          onChangeText={(newValue) => {
            // onPress(attr, newValue);
          }}
          placeholder="ZIP Code"
          style={styles.input}
        />
      </View>

      <ButtonWide
        text={"Add New Card"}
        onPress={() => {}}
        // loading={loadingUrl}
        // disabled={loadingUrl || !newValue}
      />
    </ScrollView>
  );
};

export default PaymentHOC;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
    paddingHorizontal: 20,
  },
  row: {
    marginBottom: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#D4D4D4",
    paddingVertical: 12,
    paddingLeft: 12,
    paddingBottom: 16,
    color: "#2F323F",
    fontWeight: "400",
    fontSize: 16,

    marginBottom: 24,
    outlineWidth: 0,
  },
});
