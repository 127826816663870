import React from "react";
import { ActivityIndicator, Pressable, StyleSheet, View } from "react-native";
// import LinearGradient from "react-native-linear-gradient";
import { colors } from "../../helpers/colors";
import MayaText from "../typography/MayaText";

type Props = {
  text: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  styleText?: any;
};

const ButtonWide = ({
  text,
  onPress,
  disabled,
  loading,
  style,
  styleText,
}: Props) => {
  const onPressButton = () => {
    if (disabled) {
      return;
    }
    onPress ? onPress() : null;
  };
  return (
    <Pressable
      style={[styles.container, style, disabled && styles.disabled]}
      onPress={onPressButton}
    >
      <MayaText
        style={[
          styles.buttonText,
          disabled && styles.textDisabled,
          { ...styleText },
        ]}
      >
        {text}
        {loading && (
          <View style={styles.activityIndicator}>
            <ActivityIndicator size="small" color="#0000ff" />
          </View>
        )}
      </MayaText>
    </Pressable>
  );
};

export default ButtonWide;

const styles = StyleSheet.create({
  container: {
    // width: "120%",
    backgroundColor: "#0050FF",
    // padding: 10,
    borderRadius: 10,

    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  buttonText: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    color: "white",
  },
  activityIndicator: {
    marginLeft: 10,
  },
  disabled: {
    borderRadius: 10,
    backgroundColor: "#EEEEEE",
  },
  textDisabled: {
    color: "#828BA3",
  },
});
