import React, { MutableRefObject, useState } from "react";
import {
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  Pressable,
} from "react-native";

// Components
import MayaText from "../typography/MayaText";

// Icons
import ArrowIcon from "../../icons/arrowRight";

type Props = {
  label: string;
  value: string | undefined;
  onPress?: (key: string, value: string) => void;
  onBlur?: () => void;
  enableArrow?: boolean;
  attr: string;
  isPassword?: boolean;
  onFocus?: () => void;
  ref2?: MutableRefObject<TextInput>;
  style?: any;
};

const ProfileField = ({
  label,
  value,
  onPress,
  enableArrow = true,
  attr,
  isPassword = false,
  onFocus = () => {},
  ref2 = null,
  onBlur = () => {},
  style = {},
}: Props) => {
  const [isShowPass, setIsShowPass] = useState(false);

  return (
    <View style={[{ marginBottom: 24 }, style]}>
      <MayaText
        style={{
          marginBottom: 8,
          fontWeight: "400",
          fontSize: 16,
          lineHeight: 28,
          color: "#2F323F",
        }}
      >
        {label}
      </MayaText>
      <TextInput
        secureTextEntry={isPassword && !isShowPass}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref2}
        onChangeText={(newValue) => {
          onPress(attr, newValue);
        }}
        style={[
          {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: "#D4D4D4",
            paddingVertical: 12,
            paddingLeft: 12,
            paddingBottom: 16,
            color: "#2F323F",
            fontWeight: "400",
            fontSize: 16,
            outlineWidth: 0,
          },
        ]}
      />
      {isPassword && (
        <Pressable
          onPress={() => setIsShowPass(!isShowPass)}
          style={{
            position: "absolute",
            right: 12,
            bottom: 12,
          }}
        >
          {isShowPass ? (
            <Image
              style={{ width: 24, height: 24 }}
              source={require("../../assets/newDesign/eye-bold.png")}
            />
          ) : (
            <Image
              style={{ width: 24, height: 24 }}
              source={require("../../assets/newDesign/eye-slash-bold.png")}
            />
          )}
        </Pressable>
      )}
    </View>
  );
};

export default ProfileField;

const styles = StyleSheet.create({
  container: {
    borderBottomColor: "#9F9F9F",
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 15,
  },
  title: {
    fontWeight: "500",
    fontSize: 12,
    marginBottom: 6,
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  value: {
    fontWeight: "500",
    fontSize: 16,
    marginBottom: 6,
    color: "#9F9F9F",
  },
});
