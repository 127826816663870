import { createAsyncThunk } from "@reduxjs/toolkit";
import { requests } from "../agent";
import { STORAGE_KEYS } from "../../helpers/constants";
import Toast from "react-native-toast-message";
import { getStorageData } from "../../helpers/utils";
import { Platform } from "react-native";
//import * as Sentry from "sentry-expo";
//import { sentryErrorService } from "../../services/sentry-error.service";

export enum TrainingStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type ResponseAmazonS3File = {
  status: number;
  message: string;
  filename: string;
};

const trainingAgent = {
  trainingWithPdf: (formData: any) =>
    requests.post<any>(`/training/pdf_file_upload`, formData),
  trainingMultiPdf: (formData: any, baseUrl: string) =>
    requests.post<any>(`/training/multiple_file_upload`, formData, {
      baseURL: baseUrl,
    }),
  trainingFiles: (formData: any) =>
    requests.post<any>(`/training/train_files`, formData),
  getPresignedUrl: (data: string[]) =>
    requests.post<{ presigned_upload_urls: string[] }>(
      `/training/get_presigned_url`,
      {
        filenames: data,
      }
    ),
};

export const trainingWithPdfAsyncThunk = createAsyncThunk(
  "training/trainingWithPdf",
  async (data: { formData: any }) => {
    const response = await trainingAgent.trainingWithPdf(data.formData);
    return response;
  }
);

export const trainingFilesAsyncThunk = createAsyncThunk(
  "training/trainingFiles",
  async (data: { formData: any }) => {
    const response = await trainingAgent.trainingFiles(data.formData);
    return response;
  }
);

export const trainingMultiPdfAsyncThunk = createAsyncThunk(
  "training/trainingMultiPdf",
  async (data: { formData: Partial<FormData>[] | any; baseUrl: string }) => {
    const formData =
      Platform.OS !== "web" ? data.formData["_parts"] : data.formData;

    const extractNamesOfFiles: string[] = Array.from(formData)
      .filter((file) => file[0] === "file")
      .map((file) => file[1].name as string);
    const urlPresigned = await getPresignedUrl(extractNamesOfFiles);
    const files = Array.from(formData).filter((file) => file[0] === "file");
    const apiCallTOExecute = [];
    files.forEach((file, index) => {
      apiCallTOExecute.push(sendToS3Budgets(file[1], urlPresigned[index]));
    });
    const responseAfterAmazon: ResponseAmazonS3File[] =
      await Promise.all(apiCallTOExecute);
    const fileNames = responseAfterAmazon
      .filter((response) => response.status === 200)
      .map((response) => {
        return response.filename;
      });
    if (fileNames && fileNames.length > 0) {
      await sendTitleOfBook(fileNames);
    }
    return responseAfterAmazon;
  }
);

const getPresignedUrl = async (fileNames: string[]): Promise<string[] | []> => {
  try {
    const presignedUrl = await trainingAgent.getPresignedUrl(fileNames);
    return presignedUrl.presigned_upload_urls;
  } catch (error) {
    console.error("getPresignedUrl", error);
    Toast.show({
      type: "error",
      text1: `Something went wrong`,
    });
    // sentryErrorService(error);
    return [];
  }
};

const sendToS3Budgets = async (
  file: File | any,
  presignedUrl: string
): Promise<ResponseAmazonS3File> => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", file?.type ?? file.mimeType);
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };
    const response = await fetch(presignedUrl, requestOptions as any);
    return {
      status: response.status,
      message: response.statusText,
      filename: file.name,
    };
  } catch (error) {
    // sentryErrorService(error);
    console.error("sendToS3Budgets", error);
    Toast.show({
      type: "error",
      text1: `Something went wrong`,
    });
    return {
      status: 500,
      message: "file not uploaded",
      filename: file.name,
    } as ResponseAmazonS3File;
  }
};

const sendTitleOfBook = async (fileNames: string[]) => {
  try {
    const token = await getStorageData(STORAGE_KEYS.TOKEN);
    const responseSendTitleOFBook = await requests.post<any>(
      "training/download_and_process_files",
      {
        filenames: fileNames,
      }
    );
    return responseSendTitleOFBook;
  } catch (error) {
    // sentryErrorService(error);
    console.error("sendTitleOfBook", error);
    Toast.show({
      type: "error",
      text1: `Something went wrong`,
    });
  }
};
