import { createSlice } from "@reduxjs/toolkit";
import { TOptionsWorkFlow } from "../models/workFlow.model";
import { EdgesConsumable, IDoingWork } from "../models/doingWotrk.model";
import { workFlowSendAsyncThunk } from "../api/doing-work/doing-work";
import { ChatMessage } from "../models/message";
import { GiftedChat } from "react-native-gifted-chat";
import {
  defaultPrefixRequestId,
  formatMessageForChat,
} from "../services/chat.service";
import { UUID } from "../helpers/constants";

export enum ChatStateEnum {
  TEXT = "TEXT",
  NEUTRAL = "NEUTRAL",
  UPLOADING = "UPLOADING",
}

export type ChatState = {
  promptMessage: string | undefined;
  loadingChat: boolean;
  error: string | undefined;
  state: ChatStateEnum;
  message: string;
  workFlow?: TOptionsWorkFlow | undefined;
  set?: TOptionsWorkFlow | undefined;
  activateWorkFlow: boolean;
  workOptions: IDoingWork | undefined;
  selectedByUserFlow: EdgesConsumable;
  workFlowLoading: boolean;
  messages: ChatMessage[];
};

const initialState: ChatState = {
  promptMessage: undefined,
  loadingChat: false,
  error: undefined,
  state: ChatStateEnum.NEUTRAL,
  message: "",
  workFlow: undefined,
  set: undefined,
  activateWorkFlow: false,
  workOptions: undefined,
  selectedByUserFlow: undefined,
  workFlowLoading: false,
  messages: [],
};

const ChatSlice = createSlice({
  name: "chat_slice",
  initialState,
  reducers: {
    reset: () => initialState,

    setPromptMessage: (state, action) => {
      state.promptMessage = action.payload;
    },
    setStateInput: (state, action) => {
      state.state = action.payload;
    },
    setMessagesChat: (state, action) => {
      console.log("MDEBUG", action);

      const messageFormated = {
        ...action.payload,
        createdAt: action.payload.createdAt.toString(),
      };
      let hasLoading = false;
      const removeLoadingAndGetMessage = state.messages.filter((msm) => {
        // handle duplicate loading
        if (hasLoading && msm.loading) return false;
        if (msm.loading) hasLoading = true;

        return msm._id !== action.payload._id && !msm.loading;
      });

      let prevMessage = [];
      let havePushNew = false;
      let haveLastworkflow = false;
      removeLoadingAndGetMessage.forEach((item) => {
        if (
          item.request_id.split("--")[0] ===
            action.payload.request_id.split("--")[0] &&
          item.user._id !== UUID
        ) {
          prevMessage.push(messageFormated);
          havePushNew = true;
        }

        if (
          item.request_id.split("--")[1] === "last-workflow" &&
          !action.payload.request_id.includes(defaultPrefixRequestId)
        ) {
          prevMessage.push(messageFormated);
          havePushNew = true;
          haveLastworkflow = true;
        }

        if (
          item.request_id !== action.payload.request_id ||
          item.user._id !== UUID
        ) {
          prevMessage.push(item);
        }
      });

      if (havePushNew) {
        state.messages = prevMessage;
      } else {
        state.messages = [messageFormated, ...prevMessage];
      }

      if (haveLastworkflow) {
        state.messages = state.messages.map((item) => {
          return { ...item, request_id: item.request_id.split("--")[0] };
        });
      }
    },
    toggleHeart: (state, action) => {
      state.messages = state.messages.map((item) => {
        if (item._id === action.payload._id) {
          return {
            ...item,
            isHeart: !item.isHeart,
          };
        } else {
          return item;
        }
      });
    },
    addLoadingMessageBubble: (state) => {
      const loadingMessage = formatMessageForChat({
        message: "",
        loading: true,
      });
      state.messages = GiftedChat.append(state.messages, [loadingMessage]);
    },
    setWorkFlow: (state, action) => {
      state.workFlow = action.payload;
      state.activateWorkFlow = true;
    },
    clearWorkFlow: (state) => {
      state.workFlow = undefined;
    },
    clearAllChatData: (state) => {
      for (const key in initialState) {
        if (Object.prototype.hasOwnProperty.call(initialState, key)) {
          state[key] = initialState[key];
        }
      }
    },
    removeLoading: (state) => {
      state.messages = state.messages.filter((item) => !item.loading);
    },
    stopWorkFlow: (state) => {
      state.activateWorkFlow = false;
      state.selectedByUserFlow = undefined;
      state.workFlow = undefined;
    },
    setWorkOption: (state, action) => {
      state.workOptions = action.payload;
    },
    setFlowByUser: (state, action) => {
      state.selectedByUserFlow = action.payload;
    },
    setAnswerToQuestion: (state, action) => {
      const { answer, selectedNodeToAnswer } = action.payload;

      state.workOptions = {
        nodes_consumable: state.workOptions.nodes_consumable.map((node) => {
          if (node.id === selectedNodeToAnswer.id) {
            // node.answers = action.payload.answer;
            node.answers = answer;
          }
          return node;
        }),
        edges_consumable: state.workOptions.edges_consumable,
        id: state.workOptions.id,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(workFlowSendAsyncThunk.pending, (state, action) => {
      state.workFlowLoading = true;
    });
    builder.addCase(workFlowSendAsyncThunk.fulfilled, (state, action) => {
      state.workFlowLoading = false;
    });
    builder.addCase(workFlowSendAsyncThunk.rejected, (state, action) => {
      const failMessage = formatMessageForChat({
        message: "Maya fail to generate this work flow. Please try again.",
        loading: false,
      });
      state.workFlowLoading = false;
      state.messages = GiftedChat.append(state.messages, [failMessage]);
    });
  },
});

export const {
  setPromptMessage,
  setStateInput,
  setWorkFlow,
  clearWorkFlow,
  setWorkOption,
  setFlowByUser,
  stopWorkFlow,
  setAnswerToQuestion,
  setMessagesChat,
  toggleHeart,
  removeLoading,
  addLoadingMessageBubble,
  reset,
} = ChatSlice.actions;

export default ChatSlice.reducer;
