import { configureStore } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import reducers from ".";

const persistConfig = {
  timeout: 10000,
  key: "root",
  storage: AsyncStorage,
  whitelist: ["persist"],
  version: 0,
};

const persistedReducer = persistReducer(persistConfig, reducers);

let store: any = null;

if (__DEV__) {
  store = configureStore({
    reducer: persistedReducer,
    //@ts-ignore
    // enhancers: [Reactotron.createEnhancer()],
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, immutableCheck: false

      }),
  });
} else {
  store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, immutableCheck: false

      }),
  });
}

const { dispatch } = store;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
export { dispatch };
