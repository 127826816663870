import { createAsyncThunk } from "@reduxjs/toolkit";
import { FileModel } from "../../models/file.model";
import { requests } from "../agent";

export const agent = {
  read_docs_by_user_id: () =>
    requests.get<FileModel[]>("/doc/read_docs_by_user_id"),
  training_remove_files: (ids: string[]) =>
    requests.post("/training/remove_files", {
      doc_ids: ids,
    }),
  training_url_remove: (ids: string[]) =>
    requests.post("/training/url_remove", {
      doc_ids: ids,
    }),
};

export const readDocsByUserIdAsyncThunk = createAsyncThunk(
  "document_management_slice/read_docs_by_user_id",
  async (data: {}, { rejectWithValue }) => {
    try {
      return await agent.read_docs_by_user_id();
    } catch (e) {
      console.error("readDocsByUserIdAsyncThunk", e);
      //sentryErrorService(e);
      return rejectWithValue(e);
    }
  }
);

export const trainingRemoveFilesAsyncThunk = createAsyncThunk(
  "document_management_slice/training_remove_files",
  async (data: { ids: string[] }, { rejectWithValue }) => {
    try {
      const result = await agent.training_remove_files(data.ids);
      result["ids"] = data.ids;
      return result;

      // return {
      //   message: "Success",
      //   status: "success",
      //   ids: data.ids,
      // };
    } catch (e) {
      console.error("trainingRemoveFilesAsyncThunk", e);
      //sentryErrorService(e);
      return rejectWithValue(e);
    }
  }
);

export const trainingUrlRemoveAsyncThunk = createAsyncThunk(
  "document_management_slice/training_url_remove",
  async (data: { ids: string[] }, { rejectWithValue }) => {
    try {
      const result = await agent.training_url_remove(data.ids);
      result["ids"] = data.ids;
      return result;

      // return {
      //   message: "Success",
      //   status: "success",
      //   ids: data.ids,
      // };
    } catch (e) {
      console.error("trainingUrlRemoveAsyncThunk", e);
      //sentryErrorService(e);
      return rejectWithValue(e);
    }
  }
);
