import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { FileIcon } from "../icons/FileIcon";

const InChatFileTransfer = ({ filePath }) => {
  var fileType = "";
  var name = "";
  if (filePath !== undefined) {
    name = filePath.split("/").pop();
    fileType = filePath.split(".").pop();
  }

  const [loadedPercent, setLoadedPercent] = useState(0);

  useEffect(() => {
    const storeLoop = setInterval(() => {
      if (loadedPercent < 100) {
        setLoadedPercent(
          Math.min(loadedPercent + Math.floor(Math.random() * 50), 100)
        );
      }
    }, 100);

    return () => {
      clearInterval(storeLoop);
    };
  }, [loadedPercent]);

  return (
    <View style={styles.container}>
      <FileIcon fill={"#131418"} />
      <View>
        <Text style={styles.text}>
          {name}
          {/* {`${name.replace("%20", "").replace(" ", "").substring(0, 40)}...`} */}
        </Text>
        {/* <Text style={styles.textType}>{fileType.toUpperCase()}</Text> */}
      </View>
      <View style={styles.progressBarWrapper}>
        <View
          style={[styles.progressBar, { width: `${loadedPercent}%` }]}
        ></View>
      </View>
    </View>
  );
};
export default InChatFileTransfer;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // marginTop: 5,
    // borderRadius: 15,
    // padding: 5,

    flexDirection: "row",
    flexWrap: "wrap",
  },
  text: {
    // color: "black",
    // marginTop: 10,
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 24,
    marginLeft: 10,
    // lineHeight: 20,
    // marginLeft: 5,
    // marginRight: 5,
  },
  textType: {
    // color: "black",
    // marginTop: 5,
    // fontSize: 16,
    // fontWeight: "bold",
    // marginLeft: 10,
  },
  frame: {
    // backgroundColor: "#fff",
    // flexDirection: "row",
    // borderRadius: 10,
    // padding: 5,
    // marginTop: -4,
  },
  progressBarWrapper: {
    marginTop: 10,
    width: "96%",
    backgroundColor: "#D4D4D4",
    height: 2,
    borderRadius: 10,
  },
  progressBar: {
    // borderColor: "yellow",
    // borderTopWidth: 1,
    height: 2,
    backgroundColor: "#0050FF",
    width: "0%",
    borderRadius: 10,
  },
});
