import { Platform, Pressable, Text, View } from "react-native";
// import LinearGradient from "react-native-linear-gradient";
import React from "react";
import MaskedView from "@react-native-masked-view/masked-view";
import { StyleSheet } from "react-native";

let LinearGradient;
if (Platform.OS !== "web") {
  LinearGradient = require("react-native-linear-gradient").default;
}

export const GradientButton = ({
  onPress = () => {},
  style = {},
  ...props
}) => {
  if (Platform.OS !== "web")
    return (
      <Pressable onPress={onPress}>
        <MaskedView
          maskElement={<Text {...props} style={[styles.container, style]} />}
        >
          <LinearGradient
            colors={["#0050FF", "#9000FF", "#B61CD4"]}
            start={{ x: 1, y: 0 }}
            end={{ x: -0.453, y: 0.891 }}
            locations={[0.0032, 0.8121, 0.9662]}
          >
            <Text
              {...props}
              style={[styles.container, style, { opacity: 0 }]}
            />
          </LinearGradient>
        </MaskedView>
      </Pressable>
    );

  return (
    <Pressable onPress={onPress}>
      <Text
        {...props}
        style={[
          styles.container,
          {
            background:
              "linear-gradient(117.63deg, #0050FF 0.32%, #9000FF 81.21%, #B61CD4 96.62%)",
            color: "transparent",
            WebkitBackgroundClip: "text",

            borderImage:
              "linear-gradient(117.63deg, #0050FF 0.32%, #9000FF 81.21%, #B61CD4 96.62%) 1",
            borderImageSlice: 1,
            overflow: "hidden",
          },
          style,
        ]}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1.5,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 12,
    minWidth: 120,
    textAlign: "center",

    fontSize: 14,
    fontWeight: 600,
  },
});
