import { combineReducers } from "redux";

// Import reducers
import authReducer from "../reducers/authSlice";
import worksReducer from "../reducers/worksSlice";
import generalReducer from "../reducers/generalSlice";
import insightsReducer from "../reducers/insightSlice";
import urlInputReducer from "../reducers/urlInputSlice";
import trainingReducer from "../reducers/trainingSlice";
import dimentionReducer from "../reducers/dimentionSlice";
import recognitionReducer from "../reducers/recognitionSlice";
import chatReducer from "../reducers/chatSlice";
import notificationReducer from "../reducers/notificationSlice";
import userReducer from "../reducers/userSlice";
import documentManagementReducer from "../reducers/documentManagementSlice";

// Combine reducers for the main state tree
const rootReducers = combineReducers({
  generalReducer,
  notificationReducer,
  worksReducer,
  insightsReducer,
  urlInputReducer,
  trainingReducer,
  dimentionReducer,
  recognitionReducer,
  userReducer,
  documentManagementReducer,
});

// Combine reducers for persisted state
const persistReducers = combineReducers({
  chatReducer,
  authReducer,
});

// Combine all reducers
const reducers = combineReducers({
  root: rootReducers, // Main state tree
  persist: persistReducers, // Persisted state
});

export default reducers;
