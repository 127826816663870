import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Constants
import { colors } from "../../helpers/colors";

// Components
import ButtonWide from "../general/ButtonWide";
import MayaText from "../typography/MayaText";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { RootState } from "../../../types";
import { postUrlAsyncThunk } from "../../api/urlInput/urlInput";
import {
  setClearStateOnUnLoad,
  setResponseMessage,
  UrlInputStatus,
} from "../../reducers/urlInputSlice";
import MobileViewHOC from "../../containers/MobileViewHOC";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../../navigation/routeNames";
import {
  ETrainingStatus,
  TrainingEvent,
} from "../../models/trainingEvent.model";
import { setNotification } from "../../reducers/notificationSlice";

type Params = {
  key?: string;
  name?: string;
  value?: string;
  subtitle?: string;
  buttonText?: string;
  onStartTraining?: () => {};
};

const UrlInputScreen = ({ onStartTraining }) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { responseMessage, loadingUrl, status } = useSelector(
    (state: RootState) => state.root.urlInputReducer
  );
  const { userId, token } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const route = useRoute();
  const params: Params | null = route && route.params ? route?.params : null;
  const key = params?.key || "";
  const name = params?.name || "URL";
  const subtitle =
    params?.subtitle ||
    "Team Maya, make sure this is a public site and I'm allowed to learn from it";
  const buttonText = params?.buttonText || "Start training";

  const [newValue, setNewValue] = useState("");
  const inputRef = useRef();

  const handlePost = async (value: string) => {
    dispatch(postUrlAsyncThunk({ url: value, userid: userId }));
    setNewValue("");
    dispatch(setResponseMessage("-"));

    const event = {
      message: "Training begins.",
      status: ETrainingStatus.PROCESSING,

      filename: "",
      ws_category: "TRAINING_STATUS",
      read: true,
    } as TrainingEvent;
    dispatch(setNotification(event));
    onStartTraining && onStartTraining();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    navigation.setOptions({
      title: "Learn From",
    });
    return () => {
      dispatch(setResponseMessage("-"));
    };
  }, [route, navigation]);

  useEffect(() => {
    if (responseMessage && status === UrlInputStatus.SUCCESS) {
      navigation.navigate(RouteNames.Chat);
      dispatch(setResponseMessage("-"));
    }
    return () => {
      dispatch(setClearStateOnUnLoad());
    };
  }, [responseMessage]);

  return (
    <View style={styles.container}>
      <MobileViewHOC>
        <View>
          {subtitle && <MayaText style={styles.subtitle}>{subtitle}</MayaText>}

          <TextInput
            ref={inputRef}
            style={styles.input}
            value={newValue}
            autoCapitalize="none"
            placeholder={`URL for training`}
            placeholderTextColor={colors.placeholder}
            onChangeText={setNewValue}
            secureTextEntry={key === "password"}
          />

          {responseMessage && responseMessage !== "-" && (
            <MayaText
              style={{
                fontSize: 18,
                fontWeight: "700",
                textAlign: "center",
                marginTop: 30,
              }}
            >
              {responseMessage}
            </MayaText>
          )}

          <ButtonWide
            text={buttonText || "Save"}
            onPress={() => handlePost(newValue)}
            loading={loadingUrl}
            disabled={loadingUrl || !newValue}
          />
        </View>

        {/* <View style={styles.buttonWrapper}></View> */}
      </MobileViewHOC>
    </View>
  );
};

export default UrlInputScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: "space-between",
    padding: 10,
  },
  input: {
    // backgroundColor: colors.background2,
    // borderRadius: 30,
    // padding: 10,
    // paddingHorizontal: 20,
    // margin: 20,
    // borderColor: colors.borderColor,
    // borderWidth: 1,
    // color: "#fff",
    // fontSize: 18,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderRadius: 8,
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    borderColor: "#D4D4D4",

    marginTop: 24,
    marginBottom: 24,
    outlineWidth: 0,
  },

  subtitle: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 24,
    textAlign: "center",
    color: "#5F626E",
  },
});
