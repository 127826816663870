import React, { useEffect, useState } from "react";
import {
  Text,
  Platform,
  Pressable,
  StyleSheet,
  View,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";

import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
// import * as Sentry from "sentry-expo"; // Constants
import { colors } from "../helpers/colors";
import { RootState } from "../../types";

// Components
import MayaText from "../components/typography/MayaText";
import ButtonWide from "../components/general/ButtonWide";

// Icons
import PickFromIcon from "../icons/pickFrom";
import { trainingMultiPdfAsyncThunk } from "../api/training/training";
import { AppDispatch } from "../store/store";
import FilesSelected from "../components/training/ChatFooter";
import { clearState, setLoading } from "../reducers/trainingSlice";
import MobileViewHOC from "./MobileViewHOC";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../navigation/routeNames";
import { sentryErrorService } from "../services/sentry-error.service";
import {
  readDocsByUserIdAsyncThunk,
  trainingRemoveFilesAsyncThunk,
  trainingUrlRemoveAsyncThunk,
} from "../api/doc/doc";
import { ETrainingStatus, TrainingEvent } from "../models/trainingEvent.model";
import { setNotification } from "../reducers/notificationSlice";
import { WrapperView } from "../components/Layout/WrapperView";

const truncateText = (str, length) => {
  if (length && str.length > length) return `${str.substr(0, length)}...`;

  return str;
};

const Checkbox = ({
  checked = false,
  onChange = (newValue: boolean) => {},
  style = {},
}) => {
  return (
    <Pressable
      onPress={() => {
        onChange(!checked);
      }}
      style={style}
    >
      {checked ? (
        <Image
          style={{ width: 20, height: 20 }}
          source={require("../assets/newDesign/checkbox/checked.png")}
        />
      ) : (
        <Image
          style={{ width: 20, height: 20 }}
          source={require("../assets/newDesign/checkbox/unchecked.png")}
        />
      )}
    </Pressable>
  );
};

const TabItem = ({ title, count, isSelected, onSelect }) => {
  return (
    <Pressable
      style={[styles.tabItem, isSelected && styles.tabItemSelected]}
      onPress={onSelect}
    >
      <Text style={[styles.tabTitle, isSelected && styles.tabTitleSelected]}>
        {title}
      </Text>
      <View
        style={[
          styles.tabCountWrapper,
          isSelected && styles.tabCountWrapperSelected,
        ]}
      >
        <Text style={[styles.tabCount, isSelected && styles.tabCountSelected]}>
          {count}
        </Text>
      </View>
    </Pressable>
  );
};

const LoadMoreButton = () => {
  return null;
  return (
    <View
      style={{
        backgroundColor: "white",
        alignItems: "center",
        marginTop: 30,
        marginBottom: 50,
      }}
    >
      <Pressable
        style={{
          paddingHorizontal: 24,
          paddingVertical: 12,
          backgroundColor: "#F6F6F6",
          width: 130,
          borderRadius: 10,
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: "600" }}>Load more</Text>
      </Pressable>
    </View>
  );
};

const DocumentManagementHOC = ({ uploadType, navigation }) => {
  // const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { userId } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  const { files } = useSelector(
    (state: RootState) => state.root.documentManagementReducer
  );

  useEffect(() => {
    dispatch(readDocsByUserIdAsyncThunk({}));
  }, []);

  const route = useRoute();

  const dispatch = useDispatch<AppDispatch>();

  const [state, setState] = useState({
    selectedTab: "files",
    selectedItem: [],
  });

  const filterFiles =
    files.filter(
      (item) =>
        item.doc_type ===
        { link: "URL", media: "media", files: "DOC" }[state.selectedTab]
    ) || [];

  const isCheckAll =
    filterFiles.filter((item) => !state.selectedItem.includes(item.doc_id))
      .length === 0;

  const toggleCheckAll = () => {
    if (isCheckAll) {
      setState({
        ...state,
        selectedItem: [],
      });
    } else {
      setState({
        ...state,
        selectedItem: filterFiles.map((item) => item.doc_id),
      });
    }
  };

  return (
    <WrapperView>
      <View style={{ backgroundColor: "white", height: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: 12,
            paddingVertical: 16,

            borderBottomWidth: 1,
            borderColor: "#EEEEEE",
          }}
        >
          {/* <TabItem
          title="Media"
          count={files.filter((item) => item.doc_type === "media").length}
          isSelected={state.selectedTab === "media"}
          onSelect={() => {
            setState({
              ...state,
              selectedTab: "media",
              selectedItem: [],
            });
          }}
        /> */}
          <TabItem
            title="Files"
            count={files.filter((item) => item.doc_type === "DOC").length}
            isSelected={state.selectedTab === "files"}
            onSelect={() => {
              setState({
                ...state,
                selectedTab: "files",
                selectedItem: [],
              });
            }}
          />
          <TabItem
            title="Link"
            count={files.filter((item) => item.doc_type === "URL").length}
            isSelected={state.selectedTab === "link"}
            onSelect={() => {
              setState({
                ...state,
                selectedTab: "link",
                selectedItem: [],
              });
            }}
          />
        </View>
        <View style={{ padding: 16 }}>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "600",
              lineHeight: 24,
              color: "#5F626E",
            }}
          >
            Viewing {filterFiles.length} of {filterFiles.length}
          </Text>
        </View>
        {state.selectedItem.length > 0 && (
          <View
            style={{
              paddingHorizontal: 16,
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 16,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontWeight: "600",
                lineHeight: 24,
                color: "#5F626E",
                flex: 1,
              }}
            >
              {state.selectedItem.length}{" "}
              {state.selectedTab === "files"
                ? "Files"
                : state.selectedTab === "link"
                  ? "Links"
                  : "Photos"}{" "}
              selected
            </Text>
            <Pressable
              style={{ flexDirection: "row", alignItems: "center" }}
              onPress={toggleCheckAll}
            >
              <Checkbox checked={isCheckAll} onChange={toggleCheckAll} />
              <Text
                style={{
                  fontSize: 14,
                  lineHeight: 24,
                  color: "#131418",
                  marginLeft: 8,
                  marginRight: 16,
                }}
              >
                Select all
              </Text>
            </Pressable>
            <Pressable
              style={{
                // borderWidth: 1,
                paddingVertical: 12,
                paddingHorizontal: 16,
                backgroundColor: "#F6F6F6",
                borderRadius: 8,
              }}
              onPress={() => {
                if (state.selectedTab === "files")
                  dispatch(
                    trainingRemoveFilesAsyncThunk({ ids: state.selectedItem })
                  );
                else
                  dispatch(
                    trainingUrlRemoveAsyncThunk({ ids: state.selectedItem })
                  );
                setState({
                  ...state,
                  selectedItem: [],
                });

                const event = {
                  message: `Start removing: ${filterFiles
                    .filter((item) => {
                      return state.selectedItem.includes(item.doc_id);
                    })
                    .map((item) => item.doc_name)}.`,
                  status: ETrainingStatus.PROCESSING,

                  filename: "",
                  ws_category: "TRAINING_STATUS",
                  read: true,
                } as TrainingEvent;
                dispatch(setNotification(event));
              }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../assets/newDesign/trash.png")}
              />
            </Pressable>
          </View>
        )}

        {state.selectedTab === "media" && (
          <ScrollView>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {filterFiles.map((item, index) => {
                const date = new Date(item.created_at);
                const onPress = () => {
                  const newValue = !state.selectedItem.includes(item.doc_id);
                  if (newValue) {
                    setState({
                      ...state,
                      selectedItem: [...state.selectedItem, item.doc_id],
                    });
                  } else {
                    setState({
                      ...state,
                      selectedItem: state.selectedItem.filter(
                        (i) => i !== item.doc_id
                      ),
                    });
                  }
                };

                return (
                  <Pressable
                    key={index}
                    style={{
                      width: Dimensions.get("window").width / 3,
                      height: Dimensions.get("window").width / 3,
                      padding: 1,
                    }}
                    onPress={onPress}
                  >
                    <Checkbox
                      style={{
                        // marginBottom: -30,
                        zIndex: 1,
                        // margingTop: 30,
                        // marginLeft: 10,
                        width: 20,
                        height: 20,
                        position: "absolute",
                        top: 5,
                        left: 5,
                      }}
                      checked={state.selectedItem.includes(item.doc_id)}
                      onChange={onPress}
                    />

                    <Image
                      style={{ width: "100%", height: "100%" }}
                      source={{ uri: item.doc_path }}
                    />
                  </Pressable>
                );
              })}
            </View>
            <LoadMoreButton />
          </ScrollView>
        )}

        {state.selectedTab === "files" && (
          <ScrollView>
            <View style={{ paddingHorizontal: 16 }}>
              {filterFiles.map((item, index) => {
                const date = new Date(item.created_at);
                const onPress = () => {
                  const newValue = !state.selectedItem.includes(item.doc_id);
                  if (newValue) {
                    setState({
                      ...state,
                      selectedItem: [...state.selectedItem, item.doc_id],
                    });
                  } else {
                    setState({
                      ...state,
                      selectedItem: state.selectedItem.filter(
                        (i) => i !== item.doc_id
                      ),
                    });
                  }
                };

                const status = item.status || "Completed";

                return (
                  <Pressable
                    key={index}
                    style={[
                      fileStyles.tabItem,
                      index % 2 === 0 && fileStyles.tabItemOdd,
                    ]}
                    onPress={onPress}
                  >
                    <Checkbox
                      checked={state.selectedItem.includes(item.doc_id)}
                      onChange={onPress}
                    />
                    <Image
                      style={{ marginHorizontal: 16, width: 24, height: 24 }}
                      source={require("../assets/newDesign/document-upload.png")}
                    />
                    <View style={{ flex: 1 }}>
                      <Text style={fileStyles.fileName}>
                        {truncateText(item.doc_name, 30)}
                      </Text>
                      <Text style={fileStyles.fileSize}>{item.doc_size}kb</Text>
                      <Text
                        style={fileStyles.createTime}
                      >{`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`}</Text>
                    </View>

                    <View style={{}}>
                      {status === "Completed" && (
                        <View
                          style={[
                            styles.statusWrap,
                            {
                              backgroundColor: "#FDA9FF",
                            },
                          ]}
                        >
                          <Text style={styles.statusText}>Completed</Text>
                        </View>
                      )}
                      {status === "Training" && (
                        <View
                          style={[
                            styles.statusWrap,
                            {
                              backgroundColor: "#9AB9FF",
                            },
                          ]}
                        >
                          <Text style={styles.statusText}>Training</Text>
                        </View>
                      )}
                      {status === "Failed" && (
                        <View
                          style={[
                            styles.statusWrap,
                            {
                              backgroundColor: "#D4D4D4",
                            },
                          ]}
                        >
                          <Text style={styles.statusText}>Failed</Text>
                        </View>
                      )}
                    </View>
                  </Pressable>
                );
              })}
            </View>
            <LoadMoreButton />
          </ScrollView>
        )}

        {state.selectedTab === "link" && (
          <ScrollView>
            <View style={{ paddingHorizontal: 16 }}>
              {filterFiles.map((item, index) => {
                const date = new Date(item.created_at);
                const onPress = () => {
                  const newValue = !state.selectedItem.includes(item.doc_id);
                  if (newValue) {
                    setState({
                      ...state,
                      selectedItem: [...state.selectedItem, item.doc_id],
                    });
                  } else {
                    setState({
                      ...state,
                      selectedItem: state.selectedItem.filter(
                        (i) => i !== item.doc_id
                      ),
                    });
                  }
                };

                const status = item.status || "Completed";

                return (
                  <Pressable
                    key={index}
                    style={[
                      fileStyles.tabItem,
                      index % 2 === 0 && fileStyles.tabItemOdd,
                    ]}
                    onPress={onPress}
                  >
                    <Checkbox
                      checked={state.selectedItem.includes(item.doc_id)}
                      onChange={onPress}
                    />
                    <Image
                      style={{ marginHorizontal: 16, width: 24, height: 24 }}
                      source={require("../assets/newDesign/paperclip.png")}
                    />
                    <View style={{ flex: 1 }}>
                      <Text style={fileStyles.fileName}>
                        {truncateText(item.doc_name, 30)}
                      </Text>
                      <Text
                        style={fileStyles.createTime}
                      >{`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`}</Text>
                    </View>
                    <View style={{}}>
                      {status === "Completed" && (
                        <View
                          style={[
                            styles.statusWrap,
                            {
                              backgroundColor: "#FDA9FF",
                            },
                          ]}
                        >
                          <Text style={styles.statusText}>Completed</Text>
                        </View>
                      )}
                      {status === "Training" && (
                        <View
                          style={[
                            styles.statusWrap,
                            {
                              backgroundColor: "#9AB9FF",
                            },
                          ]}
                        >
                          <Text style={styles.statusText}>Training</Text>
                        </View>
                      )}
                      {status === "Failed" && (
                        <View
                          style={[
                            styles.statusWrap,
                            {
                              backgroundColor: "#D4D4D4",
                            },
                          ]}
                        >
                          <Text style={styles.statusText}>Failed</Text>
                        </View>
                      )}
                    </View>
                  </Pressable>
                );
              })}
            </View>
            <LoadMoreButton />
          </ScrollView>
        )}
      </View>
    </WrapperView>
  );
};

export default DocumentManagementHOC;

const styles = StyleSheet.create({
  tabItem: {
    flex: 1,
    borderRadius: 4,
    padding: 8,
    paddingLeft: 12,
    backgroundColor: "#F6F6F6",
    flexDirection: "row",
    marginHorizontal: 4,
    justifyContent: "space-around",
  },
  tabTitle: {
    color: "#2F323F",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 24,
  },

  tabItemSelected: {
    borderRadius: 8,
    backgroundColor: "#131418",
  },
  tabTitleSelected: {
    color: "#FFFFFF",
  },
  tabCountWrapper: {
    paddingHorizontal: 7,
    marginLeft: 8,
    borderRadius: 4,
    backgroundColor: "#EEEEEE",
  },
  tabCountWrapperSelected: {
    backgroundColor: "#FDA9FF",
    borderRadius: 6,
  },
  tabCountSelected: {},
  tabCount: {
    color: "#2F323F",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 24,
  },
  statusText: {
    color: "#131418",
    fontSize: 10,
    fontWeight: "600",
    lineHeight: 24,
    textAlign: "center",
  },
  statusWrap: {
    borderRadius: 6,
    paddingHorizontal: 4,
    width: 64,
    height: 24,
  },
});

const fileStyles = StyleSheet.create({
  tabItem: {
    // borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  tabItemOdd: {
    backgroundColor: "#F6F6F6",
  },
  fileName: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 24,
    color: "#131418",
  },
  fileSize: {
    fontSize: 12,
    fontWeight: "400",
    lineHeight: 20,
    color: "#A9ACB2",
  },
  createTime: {
    fontSize: 12,
    fontWeight: "400",
    lineHeight: 20,
    color: "#5F626E",
  },
});
