import { UUID } from "../helpers/constants";
import uuid from "react-native-uuid";
import { GiftedChat } from "react-native-gifted-chat";
import { ChatMessage } from "../models/message";
import { IDoingWork, NodesConsumable } from "../models/doingWotrk.model";
import { setMessagesChat, stopWorkFlow } from "../reducers/chatSlice";
import store, { AppDispatch, dispatch } from "../store/store";
// import createPubSub, { PubSubEvents } from "./pub-sub.service";
export const defaultPrefixRequestId = "id-from-my-app-";
export interface IFormatMessageForChat {
  message: string;
  userId?: string;
  profileName?: string;
  loading?: boolean;
  timestamp?: Date;
  request_id?: any;
}

export const formatMessageForChat = ({
  message,
  profileName = "Maya",
  loading = false,
  userId = UUID,
  timestamp = new Date(),
  request_id = defaultPrefixRequestId + uuid.v4(),
}: IFormatMessageForChat): ChatMessage => {
  return {
    _id: uuid.v4(),
    text: message,
    createdAt: timestamp,
    user: {
      _id: userId,
      name: profileName,
      avatar:
        userId === UUID
          ? require("../assets/chat_maya_head.png")
          : "https://placeimg.com/140/140/any",
    },
    file: {},
    loading,
    request_id,
  };
};

export const appendMessage = (removeLoading: any[], message: any[]) => {
  return GiftedChat.append(removeLoading, message);
};

export const getSelectedNodeBasedOnStep = (
  options: IDoingWork,
  nextStep: string
): NodesConsumable | null => {
  const selectedEdge = options.edges_consumable.find(
    (edge) => edge.from_node === nextStep
  );
  // console.log('selectedEdge', selectedEdge);

  if (!selectedEdge) return null;

  const currentNode = options.nodes_consumable.find(
    (node) => node.id === selectedEdge.from_node
  );
  // console.log('currentNode', currentNode);

  return currentNode || null;
};

export const stopProcessWorkFlow = (dispatch: AppDispatch, type?: string) => {
  if (type) {
    const formatMessage = formatMessageForChat({
      message: "Task cancelled!",
    });
    dispatch(setMessagesChat(formatMessage));
    dispatch(stopWorkFlow());
  } else {
    const formatMessage = formatMessageForChat({
      message: "Something went wrong in work flow, Please try again.",
    });
    dispatch(setMessagesChat(formatMessage));
    dispatch(stopWorkFlow());
  }
};

export async function userInputToAnswer(userInput: string): Promise<any> {
  const chatState = store.getState().chat; // Replace 'chat' with the name of your chat slice
  console.log("userInputToAnswer", chatState);
  return;
}
