import React, { useEffect } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";
import ButtonWide from "../components/general/ButtonWide";

const BusinessInfoHOC = () => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer
  );

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
  };

  const pressSave = () => {
    dispatch(updateUserProfileAsyncThunk(profile as UserModel));
  };

  useEffect(() => {
    if (!profile || !profile.email) {
      dispatch(getUserAsyncThunk());
    }
  }, []);

  return (
    <ScrollView>
      <View style={{ backgroundColor: "white" }}>
        <View style={styles.sectionContainer}>
          {/* <MayaText style={styles.title}>BUSINESS INFORMATION</MayaText> */}

          <ProfileField
            label={"Company"}
            value={profile?.company}
            attr="company"
            onPress={onPress}
          />

          <ProfileField
            label={"Address"}
            value={profile?.address}
            attr="address"
            onPress={onPress}
          />

          <ProfileField
            label={"City"}
            value={profile?.city}
            attr="city"
            onPress={onPress}
          />

          <ProfileField
            label={"State"}
            value={profile?.state}
            attr="state"
            onPress={onPress}
          />

          <ProfileField
            label={"Country"}
            value={profile?.country}
            attr="country"
            onPress={onPress}
          />

          <ProfileField
            label={"Position"}
            value={profile?.position}
            attr="position"
            onPress={onPress}
          />

          <ButtonWide
            text={"Save"}
            onPress={pressSave}
            // loading={loadingUrl}
            // disabled={loadingUrl || !newValue}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default BusinessInfoHOC;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    padding: 20,
  },
  editButton: {
    alignSelf: "center",
    fontWeight: "600",
    color: colors.anakiwa,
  },
  sectionContainer: {
    // padding: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.background2,
    borderRadius: 30,
    width: "100%",
    alignSelf: "flex-start",
  },
  title: {
    fontWeight: "600",
    fontSize: 10,
    marginBottom: 12,
  },
});
