import React from "react";
import { Image, View } from "react-native";

const AvatarChat = ({ props, userId }) => {
  return (
    <View {...props}>
      <Image
        style={{
          width: 28,
          height: 28,
        }}
        source={
          props?.currentMessage?.user._id !== userId
            ? require("../../assets/newDesign/Avatar.png")
            : require("../../assets/newDesign/UserAvatar.png")
        }
      />
    </View>
  );
};

export default AvatarChat;
