import { requests } from "../agent";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";
//import * as Sentry from "sentry-expo";
import { sentryErrorService } from "../../services/sentry-error.service";

export type UrlInputParams = {
  url: string;
  userid: string;
  config?: AxiosRequestConfig;
};

const urlInputAgent = {
  postUrl: ({ userid, url, config }: UrlInputParams) =>
    requests.post<any>("/training/url_raw_upload", { url, userid }),
};

export const postUrlAsyncThunk = createAsyncThunk(
  "urlInput/postUrl",
  async (data: UrlInputParams) => {
    try {
      const sanitizedValue = data.url.toLowerCase().replace(/https:\/\//g, "");
      const newValue = "https://" + sanitizedValue;
      const response = await urlInputAgent.postUrl({
        url: newValue,
        userid: data.userid,
      });
      return response;
    } catch (e) {
      //sentryErrorService(e);
      console.error("postUrlAsyncThunk", e);
      return "Something went wrong";
    }
  },
);
