import { DocumentPickerAsset } from "expo-document-picker";
import { FC } from "react";
import { FlatList, Pressable, StyleSheet, Text, View } from "react-native";
import InChatFileTransfer from "../../containers/InChatFileTransfer";
import React from "react";
import { CloseIcon } from "../../icons/CloseIcon";

type ChatFooterProps = {
  files: DocumentPickerAsset[];
  onClearFile: (bane: string) => void;
};
const FilesSelected: FC<ChatFooterProps> = ({ files, onClearFile }) => {
  return (
    <View>
      <FlatList
        data={files}
        renderItem={({ item }) => (
          <View style={styles.itemWrapper} key={item.name}>
            <InChatFileTransfer key={item.name} filePath={item.name} />
            <Pressable
              onPress={() => onClearFile(item.name)}
              style={styles.buttonFilesSelected}
            >
              {/* <Text style={styles.textFilesSelected}>X</Text> */}
              <CloseIcon fill="#A9ACB2" />
            </Pressable>
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  filesSelectedFooter: {
    // shadowColor: "#FFFFFF26",
    // shadowOpacity: 0.37,
    // shadowRadius: 8,
    // shadowOffset: { width: 0, height: 8 },
    // elevation: 8,
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    // borderWidth: 1,
    // borderColor: "rgba(255, 255, 255, 0.18)",
    // flexDirection: "row",
    // padding: 5,
    // backgroundColor: "#FFFFFF26",
    // width: "100%",
  },
  textFilesSelected: {
    // fontSize: 18,
    // fontWeight: "bold",
    // color: "gray",
  },
  buttonFilesSelected: {
    // width: 35,
    // height: 35,
    // borderRadius: 50,
    justifyContent: "center",
    // alignItems: "center",
    // position: "absolute",
    // borderColor: "black",
    // right: 3,
    // top: -2,
    // backgroundColor: "rgba(255, 255, 255, 0.8)",
    // flex: 1,
  },

  itemWrapper: {
    height: 58,
    backgroundColor: "#F3F3F3",

    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",

    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 12,
    marginBottom: 24,
  },
});

export default FilesSelected;
