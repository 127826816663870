import React, { useEffect } from "react";
import {
  Text,
  Pressable,
  StyleSheet,
  View,
  Alert,
  TouchableOpacity,
} from "react-native";
import { RouteNames } from "../../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";

// Components

// Icons
import MenuItem from "./menuItem";

import { loginAsyncThunkLogout } from "../../api/auth/auth";
import { AppDispatch } from "../../store/store";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import VersionComponent from "../Version/Version";
import { setUserPressLogout } from "../../reducers/authSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { STORAGE_KEYS } from "../../helpers/constants";
import { Image } from "react-native";

type Props = {
  closeModal?: () => void;
  onPress?: () => void;
};

export const handleQuit = async (dispatch, callback) => {
  try {
    // Remove token from AsyncStorage
    await AsyncStorage.removeItem(STORAGE_KEYS.TOKEN);

    //Log AsyncStorage values after removing the token
    const tokenAfterLogout = await AsyncStorage.getItem(STORAGE_KEYS.TOKEN);
    console.log("Token after logout:", tokenAfterLogout);

    // Dispatch actions
    dispatch(loginAsyncThunkLogout());
    dispatch(setUserPressLogout(true));

    callback();
  } catch (error) {
    console.error("Error logging out:", error);
    // Handle error if needed
  }
};

const MenuContent = ({ closeModal, onPress }: Props) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const handleNavigation = (routeName: string) => {
    navigation?.navigate(routeName);
    closeModal();
  };

  return (
    <View style={styles.container}>
      {/* <View>
        <MenuItem
          label="User profile"
          iconPath={require("../../assets/profile.png")}
          onPress={() => handleNavigation(RouteNames.Profile)}
        />
      </View> */}
      <View style={{ marginTop: 0 }}>
        <TouchableOpacity
          style={styles.menuItemWrapper}
          onPress={() => {
            navigation.navigate(RouteNames.Chat);
            onPress;
            closeModal();
          }}
        >
          <Image
            source={require("../../assets/newDesign/burgerCollapse.png")}
            style={{ width: 24, height: 24 }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <MenuItem
          label="Chat"
          iconPath={require("../../assets/newDesign/Chat.png")}
          onPress={() => {
            navigation.navigate(RouteNames.Chat);
            setTimeout(() => {
              closeModal();
            }, 300);
          }}
        />
        <MenuItem
          label="Document Management"
          iconPath={require("../../assets/newDesign/document-upload.png")}
          onPress={() => {
            handleNavigation(RouteNames.DocumentManagement);
          }}
        />
        <MenuItem
          label="Settings"
          iconPath={require("../../assets/newDesign/SettingsButton.png")}
          onPress={() => handleNavigation(RouteNames.SettingsMenuHoc)}
        />
      </View>

      <View
        style={{
          alignSelf: "center",
          position: "absolute",
          bottom: "10%",
        }}
      >
        <Pressable
          onPress={() => {
            handleQuit(dispatch, () => {
              handleNavigation(RouteNames.Login);
              closeModal();
            });
          }}
          style={{
            borderRadius: 8,
            borderWidth: 1.5,
            borderColor: "#FF5858",
            width: 134,
            height: 40,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: 14,
              lineHeight: 34,
              color: "#FF5858",
            }}
          >
            Log Out
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default MenuContent;

const styles = StyleSheet.create({
  container: {
    // justifyContent: "center",
    // flexDirection: "column",
    // paddingHorizontal: 16,
    // left: 16,
    height: "100%",
    // justifyContent: "space-between",
    marginTop: 32,
  },
  menuItemWrapper: {
    // flexDirection: "row",
    // alignItems: "center",
    marginBottom: 40,
    // right: "10%",
  },
});
