import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgComponent = (props) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0979 5.40257C9.94793 5.25257 9.75793 5.18257 9.56793 5.18257C9.37793 5.18257 9.18793 5.25257 9.03793 5.40257L2.96793 11.4726C2.67793 11.7626 2.67793 12.2426 2.96793 12.5326L9.03793 18.6026C9.32793 18.8926 9.80793 18.8926 10.0979 18.6026C10.3879 18.3126 10.3879 17.8326 10.0979 17.5426L5.30805 12.7527L20.498 12.7527C20.908 12.7527 21.248 12.4127 21.248 12.0027C21.248 11.5927 20.908 11.2527 20.498 11.2527L5.30781 11.2527L10.0979 6.46257C10.3879 6.17257 10.3879 5.69257 10.0979 5.40257Z"
      fill="#2F323F"
    />
  </Svg>
);

export default SvgComponent;
