import { createSlice } from "@reduxjs/toolkit";
import { FileModel } from "../models/file.model";
import { updateUserProfileAsyncThunk } from "../api/user/user";
import {
  readDocsByUserIdAsyncThunk,
  trainingRemoveFilesAsyncThunk,
  trainingUrlRemoveAsyncThunk,
} from "../api/doc/doc";

export type DocumentManagementState = {
  files: FileModel[];
  loading: boolean;
  error: string | undefined;
};

const initialState: DocumentManagementState = {
  files: [],
  loading: false,
  error: undefined,
};

const documentManagementSlice = createSlice({
  name: "document_management_slice",
  initialState,
  reducers: {
    // setUserProfile: (state, { payload }) => {
    //   state.user = payload;
    // },
    // setKeyFromProfile: (state, { payload }) => {
    //   //@ts-ignore
    //   state.user = {
    //     ...state.user,
    //     [payload.key]: payload.value,
    //   };
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(updateUserProfileAsyncThunk.pending, (state) => {
    //   state.loading = true;
    // });
    builder.addCase(
      readDocsByUserIdAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.files = payload;

        state.loading = false;
        state.error = undefined;
      }
    );
    builder.addCase(readDocsByUserIdAsyncThunk.rejected, (state) => {
      state.files = [];
    });

    builder.addCase(
      trainingRemoveFilesAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.files = state.files.filter((item) => {
          return !payload.ids.includes(item.doc_id);
        });
        state.loading = false;
        state.error = undefined;
      }
    );

    builder.addCase(
      trainingUrlRemoveAsyncThunk.fulfilled,
      (state, { payload }) => {
        state.files = state.files.filter((item) => {
          return !payload.ids.includes(item.doc_id);
        });
        state.loading = false;
        state.error = undefined;
      }
    );
  },
});

export default documentManagementSlice.reducer;
export const {} = documentManagementSlice.actions;
