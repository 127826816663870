import React, { useState, useEffect, useRef } from "react";
import { View, Animated, Text, StyleSheet } from "react-native";
import { GradientBox } from "../typography/GradientBox";

const tickSize = 70;
let globalTimeinterval;
export const ListeningWave2 = ({ voiceAmplitude, pause, needClear }) => {
  const [rangeVoice, setRangeVoice] = useState([]);
  // useEffect(() => {
  //   const lastVoice = rangeVoice.slice(-1)[0];
  //   if (!pause && lastVoice != voiceAmplitude) {
  //     const newRange = rangeVoice.slice(-tickSize);
  //     newRange.push(voiceAmplitude);
  //     setRangeVoice(newRange);
  //   }
  // }, [voiceAmplitude, rangeVoice]);

  useEffect(() => {
    if (globalTimeinterval) clearInterval(globalTimeinterval);
    globalTimeinterval = setInterval(() => {
      if (!pause) {
        const newRange = rangeVoice.slice(-tickSize);
        newRange.push(Math.round(Math.random() * 14));
        setRangeVoice(newRange);
      }
    }, 200);
  }, [pause, rangeVoice]);

  useEffect(() => {
    setRangeVoice([]);
  }, [needClear]);

  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {rangeVoice.map((item, index) => {
        return (
          <GradientBox style={[styles.tick]} key={index}>
            <View style={[{ height: item }]}></View>
          </GradientBox>
        );
      })}
      {tickSize - rangeVoice.length > 0 &&
        [...new Array(tickSize - rangeVoice.length)].map((_, index) => {
          return <View style={[styles.tick]} key={index}></View>;
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  fadingContainer: {
    padding: 20,
    backgroundColor: "powderblue",
  },
  fadingText: {
    fontSize: 28,
    textAlign: "center",
  },
  tick: {
    width: 2,
    backgroundColor: "#D4D4D4",
    borderRadius: 10,
    minHeight: 3,
    marginRight: 2,
    maxHeight: 13,
  },
});
