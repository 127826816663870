import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import React from "react";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation, useRoute } from "@react-navigation/native";
import { RouteNames } from "../../navigation/routeNames";
import { isWireScreen } from "../../helpers/utils";

export const WrapperView = ({ children }) => {
  if (!isWireScreen()) return children;

  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const route = useRoute();
  const currentRoute = route.name;

  return (
    <View style={{ height: "100%", flexDirection: "row" }}>
      <View
        style={{
          width: 292,
          backgroundColor: "white",
          padding: 16,
          paddingTop: 40,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.navigate(RouteNames.Chat);
          }}
        >
          <View
            style={[
              styles.menuItem,
              currentRoute === RouteNames.Chat && styles.menuItemActive,
            ]}
          >
            <Image
              style={[
                styles.menuItemImage,
                currentRoute === RouteNames.Chat && styles.menuItemImageActive,
              ]}
              source={require("../../assets/newDesign/Chat.png")}
            />
            <Text
              style={[
                styles.menuItemText,
                currentRoute === RouteNames.Chat && styles.menuItemTextActive,
              ]}
            >
              Chat
            </Text>
          </View>
        </TouchableOpacity>
        <View
          style={{
            borderBottomColor: "#F2F2F2",
            borderBottomWidth: 1,
            marginBottom: 24,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate(RouteNames.DocumentManagement);
          }}
        >
          <View
            style={[
              styles.menuItem,
              currentRoute === RouteNames.DocumentManagement &&
                styles.menuItemActive,
            ]}
          >
            <Image
              style={[
                styles.menuItemImage,
                currentRoute === RouteNames.DocumentManagement &&
                  styles.menuItemImageActive,
              ]}
              source={require("../../assets/newDesign/document-upload.png")}
            />
            <Text
              style={[
                styles.menuItemText,
                currentRoute === RouteNames.DocumentManagement &&
                  styles.menuItemTextActive,
              ]}
            >
              Document Management
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate(RouteNames.SettingsMenuHoc);
          }}
        >
          <View
            style={[
              styles.menuItem,
              [
                RouteNames.SettingsMenuHoc,
                RouteNames.PasscodeEdit,
                RouteNames.Profile,
              ].includes(currentRoute) && styles.menuItemActive,
            ]}
          >
            <Image
              style={[
                styles.menuItemImage,
                [
                  RouteNames.SettingsMenuHoc,
                  RouteNames.PasscodeEdit,
                  RouteNames.Profile,
                ].includes(currentRoute) && styles.menuItemImageActive,
              ]}
              source={require("../../assets/newDesign/SettingsButton.png")}
            />
            <Text
              style={[
                styles.menuItemText,
                [
                  RouteNames.SettingsMenuHoc,
                  RouteNames.PasscodeEdit,
                  RouteNames.Profile,
                ].includes(currentRoute) && styles.menuItemTextActive,
              ]}
            >
              Setting
            </Text>
          </View>
        </TouchableOpacity>
        {/* <MenuItem
          label="Chat"
          iconPath={require("../../assets/newDesign/Chat.png")}
          onPress={() => {
            navigation.navigate(RouteNames.Chat);
            setTimeout(() => {
              closeModal();
            }, 300);
          }}
        />
        <MenuItem
          label="Document Management"
          iconPath={require("../../assets/newDesign/document-upload.png")}
          onPress={() => {
            handleNavigation(RouteNames.DocumentManagement);
          }}
        />
        <MenuItem
          label="Settings"
          iconPath={require("../../assets/newDesign/SettingsButton.png")}
          onPress={() => handleNavigation(RouteNames.SettingsMenuHoc)}
        /> */}
        <View
          style={{
            position: "absolute",
            bottom: 20,
            width: "100%",
            left: Platform.OS == "web" ? 0 : 16,
          }}
        >
          <Text style={styles.footerText}>Copyright © 2024 MayaAI</Text>
          <Text style={styles.footerText}>All Rights Reserved</Text>
        </View>
      </View>
      <View style={{ flex: 1, paddingTop: 20, paddingHorizontal: 20 }}>
        {currentRoute !== RouteNames.Chat && (
          <Text style={{ fontSize: 26, fontWeight: "700", paddingBottom: 14 }}>
            {route.name}
          </Text>
        )}

        <View
          style={[
            {
              backgroundColor: "white",
              flex: 1,
              paddingTop: 0,
              borderRadius: 8,
            },
            [RouteNames.Profile, RouteNames.PasscodeEdit].includes(
              currentRoute
            ) && {
              paddingTop: 20,
            },
          ]}
        >
          {children}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footerText: {
    color: "#5F626E",
    fontSize: 14,
    textAlign: "center",
    lineHeight: 24,
  },
  menuItem: {
    paddingVertical: 14,
    paddingHorizontal: 12,

    flexDirection: "row",

    marginBottom: 24,
  },
  menuItemActive: {
    backgroundColor: "#0050FF",
    borderRadius: 12,
  },
  menuItemText: {
    // color: "white",
    fontSize: 14,
    lineHeight: 24,
  },
  menuItemTextActive: {
    color: "white",
    fontWeight: "600",
  },
  menuItemImageActive: {
    tintColor: "white",
  },
  menuItemImage: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
});
