import React, { useEffect, useRef } from "react";
import { Animated, Easing, StyleSheet, View } from "react-native";
import { colors } from "../../helpers/colors";
// import LinearGradient from 'react-native-linear-gradient';

const DotChat = () => {
  const scaleAnimateDotOne = useRef(new Animated.Value(0)).current;
  const scaleInterpolate = scaleAnimateDotOne.interpolate({
    inputRange: [1, 2],
    outputRange: [2, 1],
  });
  const scaleAnimateDotTwo = useRef(new Animated.Value(0)).current;
  const scaleInterpolateTwo = scaleAnimateDotTwo.interpolate({
    inputRange: [1, 2],
    outputRange: [2, 1],
  });
  const scaleAnimateDotThree = useRef(new Animated.Value(0)).current;
  const scaleInterpolateThree = scaleAnimateDotThree.interpolate({
    inputRange: [1, 2],
    outputRange: [2, 1],
  });
  const animateElement = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(scaleAnimateDotOne, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(scaleAnimateDotOne, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.delay(50),
        Animated.timing(scaleAnimateDotTwo, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(scaleAnimateDotTwo, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.delay(50),
        Animated.timing(scaleAnimateDotThree, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(scaleAnimateDotThree, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
      ])
    ).start();
  };
  const animationStyleOne = {
    transform: [
      {
        scale: scaleInterpolate,
      },
    ],
  };
  const animationStyleTwo = {
    transform: [
      {
        scale: scaleInterpolateTwo,
      },
    ],
  };
  const animationStyleThree = {
    transform: [
      {
        scale: scaleInterpolateThree,
      },
    ],
  };

  useEffect(() => {
    animateElement();
  }, []);
  return (
    <View style={styles.container}>
      <Animated.View style={[styles.dot, animationStyleOne]}>
        {/* <LinearGradient
          colors={['#0050FF', '#9000FF', '#B61CD4']}
          style={styles.gradient}
        /> */}
      </Animated.View>
      <Animated.View style={[styles.dot, animationStyleTwo]}>
        {/* <LinearGradient
          colors={['#0050FF', '#9000FF', '#B61CD4']}
          style={styles.gradient}
        /> */}
      </Animated.View>
      <Animated.View style={[styles.dot, animationStyleThree]}>
        {/* <LinearGradient
          colors={['#0050FF', '#9000FF', '#B61CD4']}
          style={styles.gradient}
        /> */}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 42,
    height: 50,
    backgroundColor: "#F3F3F3",
    borderRadius: 14,
  },
  dot: {
    borderStyle: "solid",
    borderWidth: 0,
    borderRadius: 50,
    borderColor: colors.bubble,
    height: 4,
    width: 4,
    marginHorizontal: 9,
    overflow: "hidden",
  },
  gradient: {
    borderRadius: 50,
    height: "100%",
    width: "100%",
  },
});

export default DotChat;
